@use "../mixins/mq";

.icon {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	line-height: 0;
	font-style: normal;
	background-size: 100% 100%;
	width: var(--icon-width);
	height: var(--icon-height);
	min-width: var(--icon-width);


	svg {
		--icon-svg-width: var(--icon-width);
		--icon-svg-height: var(--icon-height);
		width: var(--icon-svg-width,100%);
		height: var(--icon-svg-height,auto);
	}

	&--header-logo {
		--icon-width: 100%;
	}
}

$icons: (
				('chevron-down', 22, 22),
				('chevron-right', 6, 11),
				('home', 17, 16),
				('login', 25, 25, null, null, null, null),
				('play', 20, 20, 20, 20, 36, 40),
		
);


@each $name, $widthSmall, $heightSmall, $widthMedium, $heightMedium, $widthLarge, $heightLarge in $icons {
	.icon-#{$name} {
		--icon-width: #{$heightSmall}px;
		--icon-height: #{$heightSmall}px;

		@if $widthMedium and $widthMedium != 0 {
			@include mq.mq($from: medium) {
				--icon-width: #{$widthMedium}px;
				--icon-height: #{$heightMedium}px;
			}
		}

		@if $widthLarge and $widthLarge !=  0  {
			@include mq.mq($from: large) {
				--icon-width: #{$widthLarge}px;
				--icon-height: #{$heightLarge}px;
			}
		}
	}
}
