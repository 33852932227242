@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";

.ticks {
  @include background-svg.to-custom-property(svg.$svg-ticks, colors.$color-success, --ticks-image);
  --ticks-size: 2rem;
  list-style-image: var(--ticks-image);
  margin-left: calc( var(--ticks-size) + 1rem );
  --list-spacing: 1rem;

  &--minus {
    @include background-svg.to-custom-property(svg.$svg-ticks-minus, colors.$color-error, --ticks-image);
  }
}


/*
@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";

.ticks {
  @include background-svg.to-custom-property(svg.$svg-ticks, colors.$color-success, --ticks-icon);
  --ticks-size: 2rem;
  
  margin: 0;

 
  
  li {
    list-style: none;
    background-image: var(--ticks-icon);
    min-height: var(--ticks-size);
    background-position: left top;
    background-repeat: no-repeat;
    background-size: var(--ticks-size) var(--ticks-size);
    padding-left: calc( var(--ticks-size) + 1rem );
  }

}
*/