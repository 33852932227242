@use "../mixins/mq";
@use "../base";

.c-text {
  .article__body & {
    &.c-first  {
      @include mq.mq($from: large) {
        margin-top: 5rem;
      }

      > .article__text > p:first-child:first-letter {
        @extend .h1;
        font-weight: 500;
        line-height: .8;
        vertical-align: text-bottom;
        letter-spacing: inherit;
      }
  
    }
  }
}
