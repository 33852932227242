@use "../mixins/mq";
.article {
  &__header {

    @include mq.mq($from: large) {
      display: grid;
      align-items: center;
      grid-template-columns:  1fr 1fr;
      width: 92.6%;
      gap: var(--gutterWidth);
      grid-auto-flow: dense;
      margin: 6rem 0;
    }
  }

  &__intro {
    padding-bottom: 4.6rem;
    order: 1;
    @include mq.mq($from: large) {
      padding-bottom: 0;
    }
  }

  &__title {
    margin: 0.2rem 0 2.7rem;

    @include mq.mq($from: large) {
      margin: 0.8rem 0 0;
    }
  }


  &__info {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-top: 2.6rem;

  }

  &__date {
    margin-top: 0;
    line-height: 1;
    margin-bottom: 0.8rem;
  }

  &__body {
    @include mq.mq($from: medium) {
      grid-area: articleBody;
    }
  }

  &__text {
    margin-left: auto;
    margin-right: auto;
    max-width: 89rem;

    @include mq.mq($from: medium) {
      padding: 0 5.5rem;

    }
  }

  &__components {
    margin-left: 0;
    margin-right: auto;

    width: 100%;
    --wrapper-gap: 0;
    @include mq.mq($from: large) {
      max-width: 105.4rem;
    }
  }

  &__products {
    margin-top: 3rem;
    display: grid;
   
    &--compact {
      gap: var(--row-gap,0 6rem);
      
      @include mq.mq($from: medium) {
        grid-template-columns: 1fr 1fr;
      }
      @include mq.mq($from: large) {
        --row-gap: 6rem;
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
