@use "../mixins/mq";

.deals {
  &__intro {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    @include mq.mq($from: large) {
      display: none;
    }
  }

  &__title {
    text-align: center;
    font-size: 4.8rem;
    color: var(--color-primary-1);
  }

  &__category-title {
    position: relative;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-size: 3rem;

    &:after {
      position: absolute;
      left: 0;
      top: 100%;
      display: block;
      width: 100%;
      height: 1px;
      content: '';
      background: var(--dottedBackground) center left repeat;
      background-size: 5px 1px;
    }
  }

  &__section {
    margin-top: 5rem;
    margin-bottom: 7.5rem;

    @include mq.mq($from: large) {
      margin-top: 10rem;
      margin-bottom: 10rem;
    }
  }

  &__listing {
    --deal-grid-column: 1/ span 12;
    --deal-price-flex-base: 1;

    list-style: none;
    margin-left: 0;
    display: grid;
    gap: 5rem 3rem ;
    grid-template-columns: repeat(12, 1fr);



    @include mq.mq($from: medium) {
      --deal-grid-column-half: auto / span 6;
      --deal-grid-column-third: auto / span 4;
    }
  }


  &__item {
    container-type: var(--deals-item-container-type, inline-size);
    container-name: deals;
    grid-column: var(--deal-grid-column);

    &--half {
      grid-column: var(--deal-grid-column-half, 1/ span 12);
    }

    &--third {
      grid-column: var(--deal-grid-column-third, 1/ span 12);
    }
  }

  &__details {
    --icon-transform: rotate(0);

    &[open] {
      --icon-transform: rotate(180deg);
    }

  }

  &__summary {
    --icon-width: 1.5rem;
    --icon-height: 1.5rem;

    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    color: var(--color-primary-1);

    
    &::-webkit-details-marker,
    &::marker {
      display: none;
      content: '';
    }

    .icon {
      --icon-width: 1.5rem;
      --icon-height: 1.5rem;
      margin-left: 1rem;
      transition: transform var(--animationEase) var(--animationDuration);
      transform: var(--icon-transform);
    }
  }

  &__filters {
    padding-top: 2rem;
  }
}
