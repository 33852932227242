@use "../mixins/mq";

.txtimg {
    display: grid;
    align-items: center;
    gap: 3rem 6rem;

    @include mq.mq($from: medium) {
       grid-template-columns: 1fr 1fr;
       
       &--reversed {
        --txtimg-image-order: 1;
       }
    }

    &__image {
        max-width: 50rem;
        width: 50%;
        margin-bottom: auto;
        order: var(--txtimg-image-order);
        position: relative;

        @include mq.mq($from: medium) {
            width: auto;
        }

        &--outline {
            border: .1rem solid var(--color-grey-300);
            padding: 1rem;
        }
    
    }

}