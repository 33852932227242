.c-fullscreen {

	.section {
		display: flex;
		justify-content: center;
		align-items: flex-end;
		min-height: 80vh;
	}

	.figure {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}
