@use "../mixins/mq";
@use "../_base/typography";

.tags {
  position: relative;
  @extend .list-reset;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.5625;
  letter-spacing: .1rem;
  z-index: 2;

  @include mq.mq($from: large) {
    margin-bottom: 1.3rem;
  }
}

.tags__link {
  --link-color: var(--color-black);
  --outline-color: var(--color-black);
  --tags-link-bg-color: var(--color-grey-200);
  background-color: var(--tags-link-bg-color);
  border-radius: 5rem;
  display: block;
  font-size: 1.4rem;
  line-height: 1.571428571;
  letter-spacing: .15rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: .1rem .6rem .1rem 1rem;

  &:before {
    content: '\D7';
    margin-right: 0.3rem;
    line-height: 1;
  }

  &:hover,
  &:focus {
    --tags-link-bg-color: var(--color-grey-500);
    --link-color: var(--color-white);
  }

}

