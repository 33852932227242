@use "../mixins/mq";

.component {
	--component-space-before: var(--component-space);
	--component-space-after: var(--component-space);
	margin-top: var(--component-space-before,0);
	margin-bottom: var(--component-space-after,0);

	&--dealHeading {
		--component-space-before: 0;
	}

	&--compact {
		--component-space-before: var(--component-compact-space);
		--component-space-after: var(--component-compact-space);
	}
	&--compact-before {
		--component-space-before: var(--component-compact-space);
	}
	&--compact-after {
		--component-space-after: var(--component-compact-space);
	}

	&__header {
		margin: var(--component-space) auto var(--component-compact-space);
		text-align: center;

	}
	&--no-space {
		--component-space-before: 0;
		--component-space-after: 0;
	}
}

.c-first {
	--component-space-before: 0;
}

.c-areaMain {
	--component-space-before: 0;
}

.c-edge + .c-edge,
.c-spaced + .c-edge {

}


.c-last {
	//--component-space-after: 0;
}


.c-textImage {
	--component-space: var(--component-space-small);
}
