@use "../_config/colors";
@use "../mixins/mq";

:root {
  --container-wdith: 160rem;
  --container-narrow: 104.8rem;

  --dottedBackground: url(../../images/dots.svg);
  --dottedBackgroundVertical: url(../../images/dots-v.svg);


  --globalFontSize: 1.8rem;
  --globalLineHeight: 1.666666667;
  --globalLetterSpacing: .45px;
  --globalHeadingSpacing: 4rem;
  --scroll-padding: 14rem;

  --deals-medium: 500px;

  --bubble-box-shadow: 0 2px 18px 0 rgba(0,0,0,0.3);

  --media-spacing: var(--globalHeadingSpacing);

  --color-white:  #{colors.$color-white};
  --color-grey-100:  #{colors.$color-grey-100};
  --color-grey-200:  #{colors.$color-grey-200};
  --color-grey-300:  #{colors.$color-grey-300};
  --color-grey-400:  #{colors.$color-grey-400};
  --color-grey-500:  #{colors.$color-grey-500};
  --color-grey-550:  #{colors.$color-grey-550};
  --color-grey-600:  #{colors.$color-grey-600};
  --color-black: #{colors.$color-black};

  --color-primary-1:  #{colors.$color-primary-1};
  --color-secondary-1:  #{colors.$color-secondary-1};
  --color-secondary-2:  #{colors.$color-secondary-2};

  --body-color: var(--color-black);
  --color-error:  #{colors.$color-error};
  --color-success:  #{colors.$color-success};
  --color-warning:  #{colors.$color-warning};

  --color-black-transparent:  #{colors.$color-black-transparent};
  --color-primary-1-transparent:  #{colors.$color-primary-1-transparent};

	--icon-width: auto;
	--icon-height: auto;

  --font-size-h1: 3.8rem;
  --font-size-h2: 3.4rem;
  --font-size-h3: 2.8rem;
  --font-size-h4: 2.2rem;
  --font-size-h5: 1.6rem;
  --font-size-h6: 1.4rem;
  --font-size-h7: 1.2rem;

  --font-weight-h1: 700;
  --font-weight-h2: 700;
  --font-weight-h3: 700;
  --font-weight-h4: 400;
  --font-weight-h5: 400;
  --font-weight-h6: 400;
  --font-weight-h7: 400;

  --line-height-h1: 1.263157895;
  --line-height-h2: 1.294117647;
  --line-height-h3: 1.357142857;
  --line-height-h4: 1.363636364;
  --line-height-h5: 1.5;
  --line-height-h6: 1.571428571;
  --line-height-h7: 1.5;

  --letter-spacing-h1: 0;
  --letter-spacing-h2: .68px;
  --letter-spacing-h3: 0.7px;
  --letter-spacing-h4: 0.55px;
  --letter-spacing-h5: 0.8px;
  --letter-spacing-h6: 0;
  --letter-spacing-h7: 0.75px;

  --spacing-top-h1: var(--globalHeadingSpacing);
  --spacing-top-h2: var(--globalHeadingSpacing);
  --spacing-top-h3: var(--globalHeadingSpacing);
  --spacing-top-h4: var(--globalHeadingSpacing);
  --spacing-top-h5: 2.4rem;
  --spacing-top-h6: 2.4rem;

  --font-family-h1: var(--titleFontFamily);
  --font-family-h2: var(--titleFontFamily);
  --font-family-h3: var(--titleFontFamily);
  --font-family-h4: var(--titleFontFamily);
  --font-family-h5: inherit;
  --font-family-h6: inherit;

  --paragraph-spacing: 1.5rem;
  --list-space-top: 1rem;

  --link-color: inherit;
  --link-color-hover: var(--color-primary-1);

  --font-size-button: 1.8rem;
  --nav-link-inline-border-color: var(--bodyFontColor);

  --button-primary-min-height: 5rem;
  --button-primary-min-width: 20rem;

  --bodyFontFamily: "Source Sans Pro", Helvetica, Arial, sans-serif;
  --titleFontFamily: "Good Office Pro", Helvetica, Arial, sans-serif;
  --titleFontCondFamily: "Good Office Pro Cond", Helvetica, Arial, sans-serif;
  --titleFontNarrowFamily: "Good Office Pro Narrow", Helvetica, Arial, sans-serif;
  --bodyFontColor: var(--color-black);

  --headerHeightSmall: 6rem;
  --headerHeight: auto;

  --hamburgerLineHeight: 2px;
  --hamburgerWidth: 30px;
  --hamburgerSpacing: 12px;

  --formBorder: 1px solid #999;
  --formBorderRadius: 4px;
  --formInputHeight: 33px;

  --animationDuration: 0.25s;
  --animationEase: cubic-bezier(.25,.46,.45,.75);

  --wrapper-gap: 1.7rem;
  --share-icon-size: 2.5rem;

  --component-space: 6rem;
  --component-space-small: 6rem;

  --component-compact-space: 2.5rem;

  --highlighted-font-size: var(--font-size-h5);

  --disclaimer-font-size: 1rem;
  --disclaimer-line-height: 1.6;
  --disclaimer-letter-spacing: 0.025rem;
  --disclaimer-padding: 1rem 0 .5rem;

  --variant-color: var(--color-grey-500);
  --media-float-space: 1.5rem;


  --card-img-border: 1px solid var(--color-grey-300);

  --line-clamp-count: 2;

  --grid-gap: 1rem;

  @include mq.mq($from: medium) {
    --grid-gap: 2rem;
    --scroll-padding: 18rem;
  }

  @include mq.mq($from: large) {
    --globalFontSize: 2rem;
    --globalLineHeight: 1.6;
    --globalHeadingSpacing: 8rem;
    --scroll-padding: 12rem;

    --paragraph-spacing: 1.8rem;
    --list-space-top: .9rem;
    --list-spacing: .9rem;

    --font-size-h1: 7rem;
    --font-size-h2: 5rem;
    --font-size-h3: 3.5rem;
    --font-size-h4: 2.6rem;
    --font-size-h5: 1.8rem;
    --font-size-h6: 1.4rem;

    --line-height-h1: 1.142857143;
    --line-height-h2: 1.2;
    --line-height-h3: 1.371428571;
    --line-height-h4: 1.461538462;
    --line-height-h5: 1.388888889;
    --line-height-h6: 1.571428571;

    --letter-spacing-h1: 0;
    --letter-spacing-h2: 1px;
    --letter-spacing-h3: 0.88px;
    --letter-spacing-h4: 0.65px;
    --letter-spacing-h5: 0.9px;
    --letter-spacing-h6: .22px;
/*
    --spacing-top-h1: 8rem;
    --spacing-top-h2: 8rem;
    --spacing-top-h3: 3rem;
    
  */
 
    --spacing-top-h5: 3rem;
    --spacing-top-h6: 3rem;


    --wrapper-gap: 3.8rem;
    --gutterWidth: 6rem;
    --share-icon-size: 3rem;

    --hr-spacing: 4rem;
    --hr-color: var(--color-grey-300);


    --component-space: 8rem;
    --component-space-small: 6rem;
    --component-compact-space: 4rem;
    --highlighted-font-size: inherit;

    --disclaimer-font-size: 1.4rem;
    --disclaimer-line-height: 1.785714286;
    --disclaimer-letter-spacing: 0.088rem;

    --media-float-space: 2.5rem;
    --grid-gap: 6rem;

  }
}

$bodyFontFamily: "Source Sans Pro", Helvetica, Arial, sans-serif;
$titleFontFamily: "Good Office Pro", Helvetica, Arial, sans-serif;

@media (prefers-reduced-motion: reduce) {
  :root {
    --animationDuration: 0;
    --animationEase: none;
  }
}
