@use "../mixins/mq";

.dealstripe {
    --dealstripe-height: 5rem;
    --dealstripe-arrow-point: calc(100% - 2.8rem);
    --dealstripe-bg-color: var(--color-grey-550);
    --dealstripe-progress-bg-color: var(--color-primary-1);
    --dealstripe-icon-size: 3rem;
    --dealstripe-shadow-color: rgba(0,0,0,0.5);
    --dealstripe-teasertext-font-size: var(--font-size-h5);
    --dealstripe-countdown-font-size: var(--font-size-h6);
    --dealstripe-countdown-big-font-size: var(--font-size-h5);
    --dealstripe-arrow-padding: 3rem;
    text-decoration: none;
    display: block;
    box-shadow: 0 2px 10px var(--dealstripe-shadow-color);
    position: relative;
    background-color: var(--dealstripe-bg-color);
    overflow: hidden;
    background-image: linear-gradient(-90deg, transparent 0px, transparent 3px,  #545454aa 3px,  #545454aa 5px);
    background-size: 5px;
    background-position: left center;
    transition: background-color var(--animationDuration) var(--animationEase);

 
    line-height: 1.3;
    color: var(--color-white);


    &--black {
        --dealstripe-progress-bg-color: var(--color-black);
        --dealstripe-shadow-color:  rgba(255,255,255,0.5);
    }

    @include mq.mq($from: large) {
        /*
        --dealstripe-height: 8rem;
        --dealstripe-arrow-padding: 7rem;
        --dealstripe-icon-size: 4rem;
        --dealstripe-x-position: 33%;
        --dealstripe-countdown-font-size: var(--globalFontSize);
        --dealstripe-teasertext-font-size: 3.8rem;
        */

        &--big {
            --dealstripe-height: 9rem;
            --dealstripe-teasertext-font-size: var(--font-size-h3);
            --dealstripe-countdown-big-font-size: var(--font-size-h4);
        }

    }


    &__progress {
        top: 0;
        left: 0;
        bottom: 0;
        height: 100%;
        width: var(--deal-global-progress, 100%);
        position: absolute;
        z-index: 1;
        filter: drop-shadow( 0 2px 10px var(--dealstripe-shadow-color) );
    }

    &__arrow {
        clip-path: polygon(0% 0%, var(--dealstripe-arrow-point) 0%, 100% 50%, var(--dealstripe-arrow-point) 100%, 0% 100%);

        padding-right: var(--dealstripe-arrow-padding);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 1rem;
        transition: background-color var(--animationDuration) var(--animationEase);



        background-color: var(--dealstripe-progress-bg-color);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: calc(100% + var(--dealstripe-arrow-padding));
        height: 100%;
  

    }
    &__content {
        margin: auto;
        position: relative;
        display: grid;
        align-items: center;
        min-height: var(--dealstripe-height);
        z-index: 1;
        text-transform: uppercase;
    }
    &__teasetext {
        font-family: var(--titleFontFamily);
        font-weight: 700;
        font-size: var(--dealstripe-teasertext-font-size,2rem);
        text-align: center;
    }

 

    &__countdown {
        font-size: var(--dealstripe-countdown-font-size);
        text-align: center;
        text-transform: none;
        font-weight: normal;

        &--big {
            --dealstripe-countdown-font-size: var(--dealstripe-countdown-big-font-size)
        }

        /* check if 600 should be global for b */
        b {
            font-weight: 600;
        }
        .icon {
            width: 2rem;
            vertical-align: middle;
            margin-right: .5rem;
        }
    }

    &__x {
        position: absolute;
        top: 0;
        left: var(--dealstripe-x-position, 20%);
        bottom: 0;
        z-index: -1;
        .icon {
            height: 100%;
            width: auto;
            svg {
                height: 100%;
                width: auto;
            }
        }
    }

    &__amount {
        font-size: var(--dealstripe-amount-font-size, inherit);
        font-variant-numeric: tabular-nums;
    }

    &__remaining {
        font-variant-numeric: tabular-nums;
    }

    &__icon {
        height: var(--dealstripe-icon-size);
        max-width: var(--dealstripe-icon-size);
        margin-left: auto;
        z-index: 1;
    }

    &__text {
    

    }
}

