@use "../mixins/mq";
@use "../_config/colors";


.nav--social-nav {
	display: block;
	margin-top: 3.8rem;
	grid-area: footerSocial;

	@include mq.mq($from: large) {
		margin-top: 0;
	}

	.nav__wrap {
		display: flex;

		@include mq.mq($from: large) {
			display: grid;
			gap: 2.5rem;
			grid-template-columns: 1fr;
			grid-template-rows: auto;
		}
	}

	.nav__item {
		margin-right: 3rem;
	}

	.nav__link {
		color: var(--white);
		opacity: 0.4;
	}


	.icon svg {
		width: 26px;
		height: 26px;
	}

	.not-touch & {
		.nav__link:hover {
			color: var(--color-primary-1);
			opacity: 1;
		}
	}
}
