@use "../mixins/mq";

.lang-chooser {
	position: absolute;
	left: 2rem;
	top: 2.5rem;
	text-transform: uppercase;
	border: 0;
	padding-right: 1.9rem;
	font-size: 1.8rem;
	color: var(--color-grey-400);
	-moz-appearance:none; /* Firefox */
	-webkit-appearance:none; /* Safari and Chrome */
	appearance:none;
	background: url(../../images/chevron-down.svg) center right no-repeat;
	background-size: 10px 6px;

	@include mq.mq($from: large) {
		position: static;
	}
}
