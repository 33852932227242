@use "../_config/colors";
@use "./vars";
@use "../mixins/mq";

$sizes: h1, h2, h3, h4, h5, h6, h7;

.font-family-body {
  font-family: var(--bodyFontFamily, vars.$bodyFontFamily);
}

.font-family-title {
  font-family: var(--titleFontFamily, vars.$titleFontFamily);
}

@each $size in $sizes {
  #{$size},
  .#{$size} {
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
    margin-top: var(--spacing-top-#{$size});
    @extend .font-family-title;
    font-weight: 700;
    &:first-child {
      --spacing-top-#{$size}: 0;
    }
  }

  .font-size-#{$size} {
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
  }
}

.link,
a {
  color: var(--link-color);
  transition: color var(--animationDuration) var(--animationEase), background-color  var(--animationDuration) var(--animationEase);
  &:hover,
  &:focus {
    --link-color: var(--link-color-hover);
  }
}

.paragraph-spacing-small {
  --paragraph-spacing: 2rem;
}

strong,
b,
.font-weight-700 {
  font-weight: 700;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-400 {
  font-weight: 400;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}


.text-align-center-small  {
  @include mq.mq($until: medium) {
    text-align: center;
  }
}
.text-align-center-medium {
  @include mq.mq($from: medium, $until: large) {
    text-align: center;
  }
}
.text-align-center-large {
  @include mq.mq($from: large) {
    text-align: center;
  }
}


.uppercase {
  text-transform: uppercase;
}

.font-color-white {
  color: var(--color-white);
}

/* media in text */
.media {
  margin-top: var(--media-spacing);
  max-width: 100% !important;

  &__image {
    display: block;
  }

  &[style*="float:left"],
  &[style*="float:right"] {
    max-width: calc( 100% - 20rem) !important;
    margin-bottom: 1rem;
  }
  &[style*="float:left"] {
    margin-right: var(--media-float-space) !important;
  }
  &[style*="float:right"] {
    margin-left: var(--media-float-space) !important;
  }

}

blockquote {
  position: relative;
  margin-top: 9.4rem;
  margin-bottom: 5rem;

  @extend .font-size-h3;
  quotes: "„" "”" "„" "’";

  &:before {
    content: open-quote;
    position: absolute;
    display: block;
    font-size: 12rem;
    line-height: 1;
    margin-bottom: 1rem;
    bottom: 100%;
  }
/*
  &:after {
    content: close-quote;
  }
*/
  @include mq.mq($from: large) {
    margin-top: 4.7rem;
    margin-bottom: 6.3rem;

    &:before {
      right: calc(100% + 1rem);
      font-size: 20rem;
      line-height: 0.2;
    }
  }
  @include mq.mq($from: xlarge) {

    &:before {
      right: calc(100% + 3.4rem);
    }
  }
}

figcaption {
  margin-top: 2rem;
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
  letter-spacing: var(--letter-spacing-h5);

}

::marker {
  color: var(--color-primary-1);
  @extend .font-weight-700;
}

ul {
  list-style: disc;
  
}

ul, ol {
  margin-top: var(--list-space-top,0);
  list-style-position: outside;
  margin-left:  var(--list-space-left,2.2rem);

  ul, ol {
    --list-space-top: 0;
  }

}
/*
ol {
  --list-spacing: 3rem;
  @include mq.mq($from: large) {
    --list-spacing: 4.8rem;

  
    li:first-child {
      --list-spacing: 0rem;
    }
    ol li:first-child{
      --list-spacing: 4.8rem;
    }
  }
}
*/

li {
  margin-top: var(--list-spacing,.5rem);
}


p {
  margin-top: var(--paragraph-spacing);

  &:only-child,
  &:first-child {
    --paragraph-spacing: 0;
  }

  .c-text & {
    &:first-child {
      --paragraph-spacing: 0;
    }
  }
}

.teaser {
  font-size: var(--font-size-h4);
  line-height: var(--line-height-h4);
  letter-spacing: var(--letter-spacing-h4);
  --paragraph-spacing: 2.4rem;
}

.list-reset {
  margin-left: 0;
  margin-top: 0;
  line-height: 1;

  @include mq.mq($from: medium) {
    margin-top: 0;
  }

  li {
    padding-left: 0;
    list-style: none;
    margin-top: 0;

    &:not(:first-child) {
      margin-top: 0;

      @include mq.mq($from: large) {
        margin-top: 0;
      }
    }
  }
}

.title-primary {

  margin-bottom: 1.7rem;
  text-transform: uppercase;
  color: var(--color-primary-1);

  @include mq.mq($from: large) {
    margin-bottom: 2.4rem;
  }
  &--black {
    color: var(--color-black);
  }
}

.no-margin {
  & + * {
    margin-top: 0;
  }
}

.color-white {
  color: var(--color-white);
}
