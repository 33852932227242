@use "../_config/colors";
@use "../_base/typography";
@use "../utils/visually-hidden";
@use "../mixins/mq";

.comments {
  display: flex;
  flex-direction: column-reverse;

  // for guest
 &.cc-w .cc-f-input {

    font-size: 1.6rem;
   border-radius: 0;
   border: 1px solid #ccc;
  }
}

.comments__section {
  background-color: var(--color-grey-200);
  margin-bottom: 3rem;

  @include mq.mq($from: large) {
    margin-bottom: 0;
  }
}

.cc-w-i {
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px solid var(--color-grey-300);

  @include mq.mq($from: large) {
    margin-top: 6rem;
    padding-top: 6rem;
  }
}

.cc-i:not(.cc-f) {
  .comments.cc-w & {
    margin-top: 4rem;
    padding-top: 4rem;
    border-top: 1px solid var(--color-grey-300);

    @include mq.mq($from: large) {
      margin-top: 6rem;
      padding-top: 6rem;
    }
  }

  .comments.cc-w [data-role="replies"] & {
    margin-top: 2rem;
    padding-top: 0;
    border-top: 0;
  }


}


.cc-i:not(.cc-f) > .cc-i-wrap {
  overflow: visible;
  padding: 3rem 2rem 2rem;
  border-radius: 2rem 2rem 2rem 0;
  background-color: var(--color-white);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);

  @include mq.mq($from: medium) {
    margin-right: 7rem;
  }

  @include mq.mq($from: large) {
    margin-right: 14rem;
    padding: 3rem 4rem 2rem;
    border-radius: 4rem 4rem 4rem 0;
  }



  [data-role="replies"] & {
    margin-right: 0;
    border-radius: 2rem 2rem 0 2rem;


    @include mq.mq($from: medium) {
      margin-left: 7rem;
    }

    @include mq.mq($from: large) {
      margin-left: 14rem;
      border-radius: 4rem 4rem 0 4rem;
    }
  }

  [data-role="replies"] [data-role="replies"] & {
    margin-left: 2rem;

    @include mq.mq($from: medium) {
      margin-left: 10rem;
    }

    @include mq.mq($from: large) {
      margin-left: 20rem;
    }
  }
}

.cc-i--not-admin > .cc-i-wrap {
  border-radius: 2rem 2rem 2rem 0 !important;
  @include mq.mq($from: large) {
    border-radius: 4rem 4rem 4rem 0 !important;
  }

}

.cc-i--admin:not(.cc-f) > .cc-i-wrap {
  color: var(--color-white);
  background: linear-gradient(45deg, #252525, colors.$color-primary-1);
}

.comments.cc-w .cc-f {
  border-top: 0;
  padding-top: 0;
}

.cc-w [data-role="reply"] .cc-f {
  margin-top: 4rem;
}


.comments.cc-w .cc-v-up {
  margin: 0 0 0 0.6rem;

  &:after {
    width: 2.5rem;
    height: 2.5rem;
    background: url(../../images/upvote.svg);
  }
}

.comments.cc-w .cc-v-down {
  margin: 0;
  flex-direction: row-reverse;
  &:after {
    width: 2.5rem;
    height: 2.5rem;
    background: url(../../images/downvote.svg);
  }
}

.comments.cc-w .cc-v-up,
.comments.cc-w .cc-v-down {
  text-indent: 0;
  display: inline-flex;
  border-radius: 2.5rem;
  background-color: var(--color-grey-200);
  align-items: center;
  padding: 12px;
  gap: 12px;

}

.comments .cc-v-count-hide {
  display: none;
}
.comments .cc-v-count:empty {
  display: none;
}

.cc-i-footer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;

  .comments.cc-w & {
    align-items: flex-end;
  }
}

.cc-i-comment {
  font-size: 1.8rem;
}

.cc-f-btn {
  .comments.cc-w & {
    height: 5rem;
    padding: 0 2rem;
    @extend .font-size-h5;
    text-transform: none;
    color: var(--color-white);
    border-radius: 2.5rem;
    border: 0;
    background-color: var(--color-primary-1);
  }

  .comments.cc-w .cc-i--admin & {
    color: var(--color-primary-1);
    background-color: var(--color-white);
  }
}

.cc-ll-i {
  margin-left: 2rem;

  &:first-child {
    margin-left: 0;
  }

  .cc-i-footer {
    @extend .font-size-h5;
  }

  .comments.cc-w & {
    &:before {
      display: none;
    }
  }
}

.cc-a,
a {
  .comments.comments & {
    color: var(--color-primary-1);
  }

  .comments.comments .cc-i--admin > .cc-i-wrap & {
    &,
    &:hover,
    &:active {
      color: var(--color-white);
    }

    &:hover,
    &:active {
      text-decoration: underline;
    }

  }
}


.cc-f-g {
  margin-top: 3rem;

  .h5 {
    margin-bottom: 3rem;
  }
}

.cc-i-author {
  margin-right: 2rem;
  font-size: 1.4rem;
}

.cc-i-date {
  .comments.cc-w & {
    font-size: 1.4rem;
  }
}

.cc-i-flag {
  .comments.cc-w & {
    width: 1.6rem;
    height: 2rem;
    background: url(../../images/flag.svg);
  }

  .comments.cc-w .cc-i--admin & {
    background: url(../../images/flag-white.svg);
  }
}

.cc-n {
  .comments.cc-w & {
    color: var(--color-primary-1);
  }

  .comments.cc-w .cc-i--admin & {
    color: var(--color-white);
  }
}

.cc-v {
  height: 5rem;
  min-width: 5rem;
  justify-content: center;
  gap: 1rem;

  form {
    line-height: 0;
  }

  .cc-i--admin & {
    color: var(--color-black);
  }
}

.cc-v--empty {
  opacity: 0;
}

.cc-i-reply {
  .comments.cc-w & {
    &.lgn-btn {
      font-size: 1.8rem;
      font-weight: 600;
      text-decoration: underline;
      color: var(--color-white);
    }
  }

  .comments .cc-i--not-admin & {
    &.lgn-btn {
      color: var(--color-primary-1);
    }


  }
}

.cc-f-row {
  gap: 2rem;

  .comments.cc-w & {
    margin: 0;
  }
}

.cc-f-col {
  .comments.cc-w & {
    padding: 0;
    max-width: 100%;
    overflow: hidden;
  }
}

.cc-f-editor {
  max-width: 100%;
  overflow: hidden;
  max-height: 25rem;
  margin-bottom: 3rem;
  background-color: var(--color-white);
}

.cc-f-textarea {
  .comments.cc-w & {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 83px);
    opacity: 0;
    z-index: -1;
  }
}

.ql-editor {
  color: var(--color-black);

  .comments & {
    min-height: 14rem;
    font-size: 1.8rem;

    &.ql-blank:before {
      font-size: 1.8rem;
      font-style: normal;
    }
  }

  .cc-i--admin & {
    color: var(--color-black);
  }
}

.cc-f-counter {
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  line-height: 25px;
}

.cc-user {
  font-size: 1.8rem;
  font-weight: 600;
}

.comments.cc-w .cc-i-image {
  width: 5rem;
  height: 5rem;
  margin-right: 0;

  @include mq.mq($from: large) {
    width: 6rem;
    height: 6rem;
  }
}


.comments.cc-w .cc-i-figure {
  border-radius: 50%;
  overflow: hidden;

  @include mq.mq($from: large) {
    margin-right: 2rem;
  }
}

.cc-i-header {
  margin-bottom: 2rem;
}

