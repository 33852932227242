@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";

.highlighted {
    display: inline-block;
    text-transform: uppercase;
    font-family: var(--titleFontFamily);
    line-height: var(--line-height-h5);
    font-size: var(--highlighted-font-size);
    letter-spacing: .6px;
    font-weight: 700;
    padding: 0 0.5rem 0.5rem 0;
    min-width: 10rem;
    @include background-svg.params(svg.$svg-highlighted, transparent, bottom, left, no-repeat, 100%, 0.85em, colors.$color-primary-1);

    @include mq.mq($from: large) {
        padding: 0 1.4rem 0.9rem 0;
        line-height: 1;
    }

}