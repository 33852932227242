@use "../mixins/mq";

.zone {
  position: relative;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.zone--top {
  padding-top: 2rem;
  gap: 4rem;

  @include mq.mq($from: medium) {
    padding-top: 6rem;
    grid-template-columns: 1fr  33.3%;
    grid-template-areas:
      "mainCenter aside"
      "mainLeftTop aside"
      "mainLeftBottom aside";
  }

  @include mq.mq($from: large) {
    padding-top: 6rem;
    gap: 4rem 6rem;
    grid-template-columns: 1fr 2.17fr 1fr;
    grid-template-areas:
      "mainLeftTop mainCenter aside"
      "mainLeftBottom mainCenter aside";
  }
}

.zone__heading {
  margin: 0 0 3rem;

  @include mq.mq($from: large) {
    margin: 0;
    border-bottom: .1rem solid var(--color-grey-600);
    grid-area: heading;
  }

}

.zone--article {
  gap: 6rem;
  @include mq.mq($from: medium) {
    grid-template-areas:
    "articleBody"
    "aside";
  }

  @include mq.mq($from: large) {
    grid-template-columns: 1fr 35.5rem;
    grid-template-areas:
      "articleBody aside";
    gap: 6rem var(--gutterWidth);
  }
}

.zone--bottom {

  //gap: 6rem;
  @include mq.mq($from: medium) {
 
    grid-template-areas:
      "aside"
      "heading"
      "infiniScroll";
 
 
  }
  @include mq.mq($from: large) {
    padding-top: var(--gutterWidth);
    grid-template-columns: 1fr 35.5rem;
    grid-template-areas:
      "heading heading"
      "infiniScroll aside";
    gap: 6rem 12.4%;
  }
}

.zone--bordered-top {
  margin-top: 2rem;

  @include mq.mq($from: large) {
    margin-top: 4rem;

    &:before {
      position: absolute;
      left: 0;
      bottom: 100%;
      display: block;
      width: 100%;
      height: 1px;
      content: '';
      background: var(--dottedBackground) center left repeat;
      background-size: 5px 1px;
    }
  }

  .c-area-main__header + & {
    margin-top: 0;
    padding-top: 3rem;

    @include mq.mq($from: large) {
      margin-top: 2rem;
      padding-top: 4rem;
    }
  }
}
