@use "../mixins/mq";

.c-three_columns__item-link {
	text-decoration: none;
	color: var(--color-black);
}

.c-three_columns__item-content {
	padding: 3rem 0 4rem;

	* + * {
		margin-top: 2rem;

		@include mq.mq($from: large) {
			margin-top: 3rem;
		}
	}
}
