@use "../mixins/mq";

.specification {
    font-weight: 600;
    margin: 2rem 0;
    letter-spacing: .5px;
    list-style: none;
    padding: 0;
    line-height: 1.545454545;

    > li {
        list-style: none;
        margin: 0;
        padding: 1rem 0 1.2rem;
      /*  border-bottom: dashed 1px var(--color-grey-300); */

        background: var(--dottedBackground) bottom left repeat-x;
        background-size: 5px 1px;

        > ul {
            margin: 0;
            list-style: none;
            > li {
                border: none;
                margin: 0%;
                padding: 0;
                font-weight: normal;
            }
        }

        @include mq.mq($from: medium) {
            display: grid;
            grid-template-columns: 1fr 64%;
            justify-content: space-between;
            gap: 2rem;
         
        }

    }
}
