@use "../mixins/mq";


.author {
    display: grid;
    gap: 3rem 16.666666666%;
    --figure-picture-position: static;
    align-items: center;

    --spacing-top-h4: 1rem;

    @include mq.mq($from: medium) {
        grid-template-columns: 1fr 1fr;
    }
}