@use "../mixins/mq";

.share {
    display: flex;
    align-items: center;
    gap: var(--share-gap,2.5rem);
    justify-content: center;

    list-style: none;
    margin: 0 auto 6rem;
    padding: 0;

    &--aside-on-large {
        display: none;
        @include mq.mq($from: large) {
            display: flex;
            flex-direction: column;
            position: absolute;
            left: 0;
            top: 2rem;
        }

    }

    @include mq.mq($from: large) {
        &--hide-on-large {
            display: none;
        }
    }

    &--collapse {
        max-width: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;
        transition: max-width var(--animationDuration) var(--animationEase);
        --share-icon-size: 2rem;
        --share-gap: 1rem;
        

        .share-action--active + & {
           max-width: 20rem;
        }
        
    }

    svg {
        width: var(--share-icon-size);
        height: var(--share-icon-size);
    }

}

.share__col {
    padding: 0;
    margin: 0;
}

.share__button {
    color: inherit;
    display: flex;
    margin: .2rem;
    transition: color var(--animationDuration) var(--animationEase);
    outline-offset: .1rem;
    &:hover,
    &:focus {
        color: var(--color-primary-1);
    }
}
