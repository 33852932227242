@use "../mixins/mq";

.c-logos__logo {
	width: 100%;
	display: inline-flex;
	justify-content: center;
	margin-bottom: 4rem;

	@include mq.mq($from: medium) {
		width: calc(100% / 3 - #{2rem});
		margin-left: 2rem;

		&:nth-child(3n + 1) {
			margin-left: 0;
		}
	}

	@include mq.mq($from: large) {
		width: calc(100% / 5 - #{6rem});
		margin-left: 6rem;

		&:nth-child(3n + 1) {
			margin-left: 6rem;
		}

		&:nth-child(5n + 1) {
			margin-left: 0;
		}
	}
}
