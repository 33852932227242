@use "../mixins/mq";

.authorsgrid {
    display: grid;
    --authorsgrid-col: 1;
    grid-template-columns: repeat(var(--authorsgrid-col), 1fr);
    gap: 1rem;
    margin: 0 8.333333333%;

    @include mq.mq($from: medium) {
        margin: 0;

        --authorsgrid-col: 3;
    }
    @include mq.mq($from: large) {
        gap: 2.5rem;
        --authorsgrid-col: 5;
    }

    &__item {

        --figure-picture-position: static;
        --figure-border-radius: 50%;
        text-align: center;
        text-decoration: none;
        display: flex;
        gap: 1.2rem;
        align-items: center;
        flex-direction: column;
        background-color: var(--color-secondary-2);
        padding: 2.2rem;


        &:hover,
        &:focus {
            --img-transform: scale(1.05);
        }

    }   
    &__name {
        margin-top: auto;
        font-weight: 600;
    }
    &__function {
        font-size: var(--font-size-h5);
        line-height: var(--line-height-h5);
        letter-spacing: var(--letter-spacing-h5);
        color: var(--body-color);
    }
    .icon {
        color: var(--color-primary-1);
    }
}