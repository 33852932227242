@use "../mixins/mq";
@use "../mixins/col";
@use "../_base/typography";
@use "../atoms/action";

.footer {
  padding: 5.5rem 0 5rem;
  background-color: var(--color-grey-600);
  color: var(--color-white);

  @include mq.mq($from: large) {
    padding: 5rem 0;
  }

  &__grid {

    @include mq.mq($from: large) {
      display: grid;
      grid-template-columns: 8rem 1fr 63rem;
      grid-template-rows: auto 1fr;
      column-gap: 6rem;
      grid-template-areas:
          "footerSocial footerText footerNav"
          "footerSocial footerCopyright footerNav";
    }
  }

  &__text {
    grid-area: footerText;
    text-transform: uppercase;
  }

  &__copyright {
    margin-top: 1.4rem;
    grid-area: footerCopyright;
  }
}
