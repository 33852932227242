.socialmedia {
    --icon-width: 2rem;
    --icon-height: var(--icon-width);
    display: inline-flex;
    list-style: none;
    gap: 1rem 1.5rem;
    list-style: none;
    margin: var(--paragraph-spacing) 0 0;
    flex-wrap: wrap;
    
    &__item {
        padding: 0;
        margin: 0;
    }

    &__link {
        border: currentColor .1rem solid;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1;
        padding: 1rem;
        border-radius: 50%;
    }
}