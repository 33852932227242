@use "../mixins/mq";

.c-page404 {

}

.c-page404__inner {
	position: relative;
	display: flex;
	padding: 6rem 0;
	margin: 0 auto;
	align-items: flex-end;
	justify-content: center;
	max-width: 180rem;
	min-height: 80rem;

	color: var(--color-white);

	border-radius: 3rem;
	overflow: hidden;
	background-color: var(--color-black);

	@include mq.mq($from: large) {
		align-items: center;
		border-radius: 6rem;
	}
}

.c-page404__container {
	position: relative;
	z-index: 1;
}
