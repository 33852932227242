@use "../mixins/mq";

:root {
    --procontra-border-width: 1.7rem;
    --procontra-box-bg-color:  var(--color-grey-100);
    --procontra-box-shadow-color: var(--color-grey-100);

    --procontra-margin: var(--procontra-border-width) var(--procontra-margin-inline, auto);
    --procontra-image-max-height: 21rem;

    @include mq.mq($from: medium) {
        --procontra-border-width: 2rem;
        --procontra-as-image-max-width: 45rem;
        --procontra-image-max-height: 33rem;
    }
}



.procontra-slider-box {
    --procontra-margin: 0;
    --procontra-box-shadow-color: transparent;
    --procontra-max-width: none;
    --procontra-as-image-max-width: none;
    background-color: var(--procontra-box-bg-color);
    box-shadow: 0 0 0 var(--procontra-border-width) var(--procontra-box-bg-color);
    display: grid;
    align-items: end;
}

.procontra {
    --figure-picture-position: static;

    background-color: var(--color-white);
    margin: var(--procontra-margin);
    padding: var(--procontra-border-width);

    box-shadow: 0 0 0 var(--procontra-border-width) var(--procontra-box-shadow-color);

    max-width: var(--procontra-max-width, 74.6rem);
    height: 100%;

    &--as-image {
        --procontra-max-width: var(--procontra-as-image-max-width);
    }

    &__media {
        margin: 1.5rem 0;
    }

    &__image {
        display: block;
        max-height: var(--procontra-image-max-height);
        object-fit: contain;
        margin: 0 auto;
        aspect-ratio: 4/3;
    }

    &__arguments {
        margin-top: 3rem;
        font-weight: 600;
    }

}