@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/mq";
@use "../_base/typography";
@use "../mixins/background-svg";



.action {
	--icon-width: 2.5rem;
	--icon-height: var(--icon-width);

	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	max-width: 100%;
	overflow: visible;
	text-decoration: none;
	background-color: var(--action-background, unset);
	transition: background-color var(--animationDuration) var(--animationEase), color var(--animationDuration) var(--animationEase), border-color var(--animationDuration) var(--animationEase);
	cursor: pointer;
	gap: 1rem;
}

@mixin action-button {
	min-height: var(--button-primary-min-height);
	min-width: var(--button-primary-min-width);
	padding: 0 1.2rem;
	letter-spacing: .09rem;
	line-height: 1.5;
	font-size: var(--font-size-button);
	border-radius: var(--button-primary-min-height);
	font-weight: bold;
	text-align: center;
	border: none;
  }

.action-primary {
	@include action-button;
	color: var(--color-white);
	--action-background: var(--color-primary-1);
	--outline-color:  var(--color-primary-1);

	&.disabled {
		background-color: var(--color-grey-300);
	}

	.not-touch & {
		&:hover {
			background-color: var(--color-secondary-1);
		}
	}

}
/*
.action-primary--inverted {
	@extend .action-primary;
	color: var(--color-primary-1);

	border-color: var(--color-white);
	background-color: var(--color-white);

	&.disabled {
		color: var(--color-white);
	}

	.not-touch & {
		&:hover {
			color: var(--color-white);
			background: none;
		}
	}
}
*/


.action-secondary {
	@include action-button;

	color: var(--color-primary-1);
	background-color: transparent;
	border: 2px solid var(--color-primary-1);

	&.disabled {
		background-color: transparent;
		color: var(--color-grey-300);
		border-color: var(--color-grey-300);
	}

	.not-touch & {
		&:hover {
			color: var(--color-white);
			border-color: var(--color-secondary-1);
			background-color: var(--color-secondary-1);
		}
	}
}


.action-secondary--inverted {
	@extend .action-secondary;
	color: var(--color-white);
	border-color: var(--color-white);
}


.action-black {
	@include action-button;

	color: var(--color-white);
	background-color: var(--color-grey-600);
	--outline-color: var(--color-black);

	&.is-active {
		background-color: var(--color-grey-500);
	}


	&.disabled {
		background-color: var(--color-grey-300);
	}

	.not-touch & {
		&:hover {
			background-color: var(--color-grey-500);
		}
	}
}

.action--small {
	--button-primary-min-height: 3.4rem;
	--button-primary-min-width: 8rem;
}

.action-fab {
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-primary-1);
	border: 0.1rem solid var(--color-primary-1);

	.not-touch & {
		&:hover {
			@include background-svg.params(svg.$svg-arrow-right, colors.$color-primary-1, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
		}
	}

	.not-touch .c-three_columns-item:hover & {
		@include background-svg.params(svg.$svg-arrow-right, colors.$color-primary-1, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
	}

	&--inverted {
		@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-white);
		border-color: var(--color-white);

		.not-touch & {
			&:hover {
				border-color: var(--color-primary-1);
			}
		}
	}

	&.disabled {
		@include background-svg.params(svg.$svg-arrow-right, transparent, center, center, no-repeat, 3rem, 2.1rem, colors.$color-grey-200);
		border-color: var(--color-grey-200);
	}
}

.action-link {
	font-weight: 600;
	font-size: 2.2rem;
	&:before {
		width: 3rem;
		height: 3rem;
		content: '';
		margin-right: 1rem;
		background-image: var(--svg-url,none);
		background-position: center center;
		background-size: cover;
	}
}

.action-download {
	@extend .action-link;
	&:before {
		@include background-svg.to-custom-property(svg.$svg-download, colors.$color-primary-1);
	}
}
.action-external {
	@extend .action-link;
	&:before {
		@include background-svg.to-custom-property(svg.$svg-external, colors.$color-primary-1);
	}
}
/*
.action-footer {
	color: var(--color-white);

	.not-touch & {
		&:hover {
			text-decoration: underline;
		}
	}
}
*/


.action-chevron {
	font-weight: 600;
	font-size: 1.8rem;


	.c-area-main & {
		margin-bottom: 1rem;
	}
}

.action--nowrap {
	white-space: nowrap;
}

.action--center {
	margin-left: auto;
	margin-right: auto;
	display: flex;
}
.action-large {
	--button-primary-min-height: 5rem;
	--button-primary-min-width: 19rem;
	--font-size-button: 2rem;
	padding: 0 1.5rem;
	letter-spacing: .06rem;
	font-weight: bold;
	gap: 0 .7rem;

	@include mq.mq($from: large) {
		--button-primary-min-height: 8rem;
		--button-primary-min-width: 37.8rem;
		--font-size-button: 2.8rem;
		gap: 0 4.2rem;

		--icon-width: 40px;
		--icon-height: 40px;
	}
}


@mixin action-with-svg {
	display: inline-flex;
	align-items: center;
	gap: 1rem;
	padding: 0;
	margin: 0;
	letter-spacing: .09rem;
	text-decoration: none;
	line-height: 1.5;
	font-weight: 600;
	font-size: 1.8rem;


    --small-link-icon-width: 2rem;
    --small-link-icon-height: 2rem;

    .icon {
        --icon-width: var(--small-link-icon-width);
        --icon-height: var(--small-link-icon-height);
    }
  }


.action-share,
.action-chevron {
	@include action-with-svg;
}


.action--stretched {
	position: static;
	&:after {
		content: "";
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		background: none;
		
	}
}

.action-voucher {
	--action-icon-transform: translateX(0);
	--action-height: 6rem;
	--action-padding: 0 3rem;
	--action-gap: 3.5rem;
	--action-border-radius: 3rem;
	--icon-svg-width: 1.2rem;
	--action-font-size: 1.6rem;
	--icon-width: 1.2rem;
	--icon-height: 1.2rem;
	justify-self: flex-end;
	align-self: flex-end;
	height: var(--action-height);
	padding: var(--action-padding);
	gap: var(--action-gap);
	font-family: var(--font-family-h1);
	font-size: var(--action-font-size);
	text-transform: uppercase;
	border-top-left-radius: var(--action-border-radius);
	background-color: var(--color-primary-1);



	@include mq.mq($from: large) {
		--action-height: 11.4rem;
		--action-padding: 0 6.3rem;
		--action-font-size: 2.6rem;
		--action-border-radius: 6rem;
		--action-gap: 4.5rem;
		--icon-width: 2.8rem;
		--icon-height: 2.8rem;


	}

	.icon {
		/*--icon-width: 5.8rem;
		--icon-height: 5.8rem;*/
		width: 4rem;
		height: 4rem;
		border: 1px solid var(--color-white);
		border-radius: 50%;
		transform: var(--action-icon-transform);
		transition: transform var(--animationDuration) var(--animationEase);

		@include mq.mq($from: large) {
			width: 5.8rem;
			height: 5.8rem;
			border: 2px solid var(--color-white);
		}
	}

	&:focus,
	&:hover {
		color: var(--color-white);
		--action-icon-transform: translateX(1rem);
	}
}

.action-filter {
	--action-height: 2.6rem;
	--action-background: var(--color-grey-200);
	padding: 0 1.3rem;
	font-size: 1.2rem;
	border-radius: calc(#{var(--action-height)} / 2);
}