@use "../_base/_typography";
.lead {
  margin-top: 4rem;

  .breadcrumb__wrapper + & {
    margin-top: 2rem;
  }

  &__text {
    margin-top: 2rem;
  }
}
