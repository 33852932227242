@use "../mixins/mq";
@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";

.argument {
    font-size: var(--font-size-h4);
    line-height: var(--line-height-h4);
    letter-spacing: var(--letter-spacing-h4);
    font-weight: 600;
    color: var(--color-grey-500);
    --paragraph-spacing: 1.4rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 3rem;


    @include background-svg.to-custom-property(svg.$svg-thumbs-up, colors.$color-grey-500,'--thumbs-up');
  
    @include mq.mq($from: medium) {
        gap: 2rem;
    }

    &--contra,
    &--pro {
        &::before {
            content: var(--thumbs-up);
            display: block;
            min-width: 2rem;
        }
    }
    &--contra {
        &::before {
            transform: scaleY(-1);
        }
    }
}