@use "../mixins/mq";


.ticker {
    --link-color-hover: var(--ticker-color,currentColor);
    --ticker-banner-bg-color: var(--color-primary-1);
    --outline-color: var(--color-black);
    --ticker-banner-font-size: var(--font-size-h5);
    --icon-height: 4.5rem;
    --icon-width: 5rem;
    --ticker-media-compact-display: block;
    --ticker-media-spaced-display: none;

    --ticker-header-font-size: inherit;
    --ticker-header-line-height: inherit;
    --ticker-header-letter-spacing: inherit;
 
    
    @include mq.mq($from: medium) {
        --icon-height: 5rem;
        --icon-width: 6rem;
        --ticker-media-compact-display: none;
        --ticker-media-spaced-display: block;
        
    }

    color: var(--ticker-color);
    display: grid;
    max-width: var(--ticker-max-width,none);
    width: 100%;
    margin: 0 auto;
    box-shadow: var(--ticker-box-shadow,none);
    overflow: hidden;
    background-image: var( --ticker-bg-image, none);
    background-color: var( --ticker-bg-color, none);
    list-style: none;
    text-decoration: none;
    padding: var(--ticker-padding);
    

    &--hero {
        --ticker-padding: 2.5rem 0;
        @include mq.mq($from: medium) {
            --ticker-padding: 4rem 0;

        }
    }

    &--teaser {
        --ticker-header-text-transform: uppercase;
        --ticker-header-text-wrap: balance;
        --ticker-max-width: 104.6rem;
        --ticker-body-padding: 2.6rem 1rem 1.2rem;
        --ticker-body-text-align: center;
        --ticker-image-max-height: 24rem;
        --ticker-media-margin: 3.4rem 0;
        --ticker-box-shadow: 0 .2rem 2rem 0 rgba(0,0,0,0.3);

      
        @include mq.mq($until: medium) {
            --ticker-header-font-size: var(--font-size-h3);
            --ticker-header-line-height: var(--line-height-h3);
            --ticker-header-letter-spacing: var(--letter-spacing-h3); 
        }
    }

    /* for promoframe only */
    &--compact {
        --ticker-action-display: none;
        --ticker-body-display: flex;
        --ticker-media-order: -1;
        --ticker-body-padding: 1.7rem 2rem;
        --ticker-image-max-height: 15.2rem;

        --icon-height: 3rem;
        --icon-width: 4rem;

        --ticker-banner-font-size: 1.3rem;

        --ticker-media-compact-display: none;
        --ticker-media-spaced-display: block;

        --ticker-media-margin: 0 auto 1rem;
        --ticker-box-shadow: none;

        --ticker-header-font-size: var(--font-size-h4);
        --ticker-header-line-height: 1.2;
        --ticker-header-letter-spacing: var(--letter-spacing-h4); 

        @include mq.mq($from: tablet) {
            --ticker-body-min-height: 39.8rem;
            --icon-height: 3.5rem;
            --ticker-media-height: 100%;
            --ticker-media-width: 100%;
            --ticker-header-font-size: var(--font-size-h3);
            --ticker-header-line-height: 1.2;
            --ticker-header-letter-spacing: var(--letter-spacing-h3); 
            --ticker-image-position: absolute;
            --ticker-image-max-height: 100%;
        
        }
    }

    &--x-compact {
        
        @include mq.mq($from: medium) {
            --ticker-media-compact-display: block;
            --ticker-media-spaced-display: none;
         
                .ticker__header {
                    font-size: var(--font-size-h3);
                }
        }
    }

    &__row {
        display: grid;
        gap: var(--grid-gap);
        align-items: center;
        @include mq.mq($from: medium) {
            grid-template-columns: 1fr 1fr;
            min-height: 52rem;
        }
    }

    &__col {
        &--media {
            margin: auto;
        }
    }

    &--dark {
        --ticker-color: var(--color-white);
        --ticker-bg-image: linear-gradient(180deg,  #252525 20%,  #E20000 133%); 
    }

    &--black {
        --ticker-color: var(--color-white);
        --ticker-bg-image: linear-gradient(190deg,  #252525 20%,  #5077BB 200%);
    }

    &--beige {
        --ticker-bg-color: var(--color-secondary-2);
    }

    &--black-week {
        --ticker-banner-bg-color: var(--color-black);
    }

    &--outline {
        --ticker-body-border: .8rem solid var(--color-primary-1);
    }

  
    &__body {
        padding: var(--ticker-body-padding);
        border: var(--ticker-body-border, none);
        text-align: var(--ticker-body-text-align, inherit);
        display: var(--ticker-body-display);
        min-height: var(--ticker-body-min-height);
        /* if flex */
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }
    
    &__action {
        display: var(--ticker-action-display);
    }

    &__date {
        margin: 1rem 0 0 ;
    }


    &__heading {
        margin: var(--ticker-heading-margin, 0)
    }

    &__header {
        display: block;
        margin: 1rem 0;
        text-wrap: var(--ticker-header-text-wrap);
        text-transform: var(--ticker-header-text-transform);
        font-size: var(--ticker-header-font-size);
        line-height: var(--ticker-header-line-height);
        letter-spacing: var(--ticker-header-letter-spacing); 
    }

    &__media {
        margin: var(--ticker-media-margin);
        
        order:  var(--ticker-media-order);
        position: relative;
        height: var(--ticker-media-height,auto);
        width: var(--ticker-media-width,auto);
        &--spaced {
            display:  var(--ticker-media-spaced-display, block);
        }
        &--compact {
            display:  var(--ticker-media-compact-display, block);
        }
    }

    &__image {
        max-height: var(--ticker-image-max-height);
        width: auto;
        margin: auto;
        object-fit: contain;
        inset: 0;
        position: var(--ticker-image-position);
    }

    &__footer {
        overflow: hidden;
    }

    &__banner {
        user-select: none;
        font-size: var(--ticker-banner-font-size);
        display: inline-flex;
        gap: 0 2rem;
        white-space: nowrap;
        align-items: center;
        background-color: var(--ticker-banner-bg-color);
        color: var(--color-white);
        text-transform: uppercase;
        will-change: transform;
        animation: scroll-banner 33s linear infinite;
        padding-left: 2rem;
    }
}

@keyframes scroll-banner {
    from {
        transform: translateX(0%);
      }
      to {
        transform: translateX(-33.3333333%);
      }
}
