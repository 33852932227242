@use "../mixins/mq";

.voucher-teaser {
  --voucher-teaser-padding: 26rem 0 0 1.8rem;
  --voucher-teaser-text-padding: 0 3rem 0 0;

  @include mq.mq($from: large) {
    --voucher-teaser-padding: 10rem 0 0 0;
  }

  position: relative;
  display: flex;
  justify-content: flex-end;
  min-height: 40rem;
  color: var(--color-white);
  background-color: var(--color-black);

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    justify-content: space-between;
    max-width: 52rem;
    padding: var(--voucher-teaser-padding);
  }

  &__text {
    padding: var(--voucher-teaser-text-padding);
  }

  &__image {
    --focalpoint: center top;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    @include mq.mq($from: medium) {
      --focalpoint: left center;
    }
  }
}