@use "_common";
@use "plugins";
@use "atoms";
@use "molecules";
@use "organisms";

.block {
	padding: 8rem;
}

.block--inverted {
	background-color: #89969F;
}
