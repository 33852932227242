@use "../mixins/mq";
@use "../_base/typography";

.card {
  position: relative;
  /*overflow: hidden;*/

  &__link {
    display: block;
    //position: relative;
    text-decoration: none;

    &--image {
      padding-bottom: 1rem;

      @include mq.mq($from: large) {
        padding-bottom: 1.45rem;
      }

      .card--aside & {
        padding-bottom: 0;
      }

      .card--horizontal & {
        @include mq.mq($from: medium) {
          padding-bottom: 0;
        }
      }
    }

    &--image-spacing-short {
      padding-bottom: 2rem;
    }

    &--text {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: block;
        opacity: 0;
        z-index: 1;
      }
     
    }
  }

  &__title {
    margin-top: var(--card-title-margin-top,0);
    font-family: var(--titleFontFamily);

    &--small {
      font-size: 1.6rem;
      line-height: 1.4375;
      letter-spacing: .08rem;
      font-weight: 500;
    }


    .card--product & {
      font-family: var(--bodyFontFamily);
      font-weight: 700;
    }
  }

  &--big {
    @include mq.mq($from: medium) {
      grid-area: mainCenter;
    }
  }

  &--horizontal,
  &--number,
  &--aside {
    background: var(--dottedBackground) left bottom repeat-x;
    background-size: 5px 1px;
  }

  &--number,
  &--aside {

    display: grid;
    gap: 2rem;
    margin-top: 2rem;
    padding-bottom: 2rem;
    grid-template-columns: auto 1fr;

    @include mq.mq($from: medium) {
      margin-top: 1.45rem;
      padding-bottom: 1.45rem;
    }

    &:first-child {
      margin-top: 0;
    }

    .tags {
      margin: 0;
    }

  }

  &--number {
    grid-template-columns: auto 1fr;
    gap: 2.2rem;
  }

  &--aside {
    gap: 1.5rem;
    text-align: left;
    align-items: center;
    .card__link--image {
      margin: 0 0 auto;
      display: block;
    }
  

  }

  &--horizontal {
    display: grid;
    gap: 0;
    grid-template-columns: 1fr;
    margin-bottom: 5rem;
    padding: 0 0 3rem;
    margin-bottom: 3rem;

    @include mq.mq($from: medium) {
      grid-template-columns: auto 1fr;
      gap: 6rem;
      padding: 0 0 2rem;
      margin-bottom: 2rem;
      .figure {
        width: 27.8rem;
      }
    }


  }


  &--product {

    margin: 3rem 0;
    --card-title-margin-top: 1.8rem;
    --card-content-padding-top: 2rem;
    --img-border: var(--card-img-border);
    --img-padding: .8rem;
    --card-figure-aspect-ratio: 340/260;

    display: grid;
    grid-template-rows: auto 1fr;

 


    &:before,
    &:after {
      position: absolute;
      left: 0;
      bottom: -3rem;
      display: block;
      width: 100%;
      height: 1px;
      content: '';
      background: var(--dottedBackground) center left repeat;
      background-size: 5px 1px;
    }

    &:before {
      bottom: auto;
      top: -3.1rem;
    }


  }
  @include mq.mq($from: large) {
  .article__products--compact &,
   &--product.card--productRegular {

        margin: 0;
        &:first-child:before,
        &:after {
          display: none;
        }

        &:not(:first-child):before {
          position: absolute;
          left: -3rem;
          top: 0;
          display: block;
          width: 1px;
          height: 100%;
          content: '';
          background: var(--dottedBackgroundVertical) center left repeat;
          background-size: 1px 6px;
        }
      }
    }


    @include mq.mq($from: medium) {
      &--product.card--productRelated {
        --card-title-margin-top: 0;
        --card-content-padding-top: 0;
        --card-figure-aspect-ratio: 356/400;

        display: grid;
        grid-template-columns: 3fr 4fr;
        grid-template-rows: auto;
        gap: 6rem;
        align-items: center;
      }
  }
  .article__products--compact & {

    display: grid;
    grid-template-columns: 14rem 1fr;
    grid-template-rows: none;
    gap: 2.8rem;
    --card-title-margin-top: 0;
    --card-content-padding-top: 0;
    --card-figure-aspect-ratio: 1/1;

      //set as small
    --button-primary-min-height: 3.4rem;
    --button-primary-min-width: 8rem;


    @include mq.mq($from: small) {
      grid-template-columns: 35fr 85fr;
    }

    @include mq.mq($from: medium) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        align-items: stretch;
        gap: 0;
        --card-title-margin-top: 2rem;
        --card-figure-aspect-ratio: 340/260;
    }

}


  &__footer {
    margin-top: auto;
  }

  &__content {
    display: grid;
    --paragraph-spacing: 2rem;
    padding-top: var(--card-content-padding-top,0);
    align-items: center;

    .card--horizontal & {
      padding-bottom: 0;
      align-self: center;
      @include mq.mq($from: medium) {
        --card-content-padding-top: 0;
      
      }
    }


  }

  &__number {
    display: block;
    min-width: 4.8rem;
    @extend .h3;
    line-height: 1;
    text-align: center;
    color: var(--color-primary-1);
    text-decoration: none;
  }
/*
  &--product {
    display: grid;
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include mq.mq($from: medium) {
      grid-template-columns: 1fr 1fr;
      gap: 6rem;
      padding-top: 6rem;
      padding-bottom: 6rem;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 0;
    }
  }
*/
  &__sale {
    display: inline-block;
    height: 3rem;
    padding: 0 1.5rem;
    font-size: 2.2rem;
    line-height: 2.8rem;
    font-weight: 600;
    border-radius: 1.1rem;
    color: var(--color-white);
    background-color: var(--color-primary-1);
    transform: var(--card-sale-transform, none);

    .card--product & {
      position: absolute;
      left: 0;
      top: 0;
      --card-sale-transform: translate(1rem, -50%);

      @include mq.mq($from: large) {
        --card-sale-transform: translate(1.6rem, 1.6rem);
      }
    }
  }

  &__price {
    font-family: var(--titleFontFamily);
    font-size: 2.4rem;
    font-weight: 700;
    color: var(--color-primary-1);

    @include mq.mq($from: large) {
      font-size: 2.8rem;
    }

    .card--product & {
      margin-top: 1rem;
      font-size: 2rem;

      @include mq.mq($from: large) {
        font-size: 2.2rem;
      }
    }
  }

  &__originalPrice {
    font-family: var(--bodyFontFamily);
    font-size: 2rem;
    font-weight: 400;
    color: var(--color-grey-400);

    @include mq.mq($from: large) {
      font-size: 2.2rem;
    }

    .card--product & {
      font-size: 1.8rem;
    }
  }
}



