@use "sass:color";
@use "../_config/colors";
@use "../mixins/mq";

video,
img {
	width: 100%;
	height: auto;
	object-fit: var(--object-fit, cover);
	padding: var(--img-padding, 0);
}

.img {
	&-sticker {
		display: block;
	}
}

.figure {
	position: relative;
	overflow: hidden;
	border-radius: var(--figure-border-radius);

	&:after {
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		content: '';
	}

	picture,
	img,
	video {
		display: block;
		position: var(--figure-picture-position,absolute);
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	img {
		transition: transform var(--animationDuration) var(--animationEase);
		transform: var(--img-transform);
		image-rendering: -webkit-optimize-contrast;
		backface-visibility: hidden;
		border: var(--img-border,none);
		object-position: var(--focalpoint);
	}

	&-three {
		height: 0;
		padding-bottom: 76%;
	}

	&-two {
		height: 0;
		padding-bottom: 72.25%;
	}

	&-fullscreen:after,
	&-cta:after {
		background-color: color.adjust(colors.$color-black, $alpha: -0.55);
	}
}

.figure-headerArticle {
	aspect-ratio: 375/322;
	margin-left: calc(-1 * #{var(--wrapper-gap)});
	margin-right: calc(-1 * #{var(--wrapper-gap)});
	margin-bottom: 3rem;

	@include mq.mq($from: medium) {
		aspect-ratio: 16/9;
		width: auto;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 6rem;
	}

	@include mq.mq($from: large) {
		aspect-ratio: 633/543;
		max-width: 63.3rem;
		margin-bottom: auto;
	}
}

.figure-authorArticle {
	width: 7rem;
	height: 7rem;
	border-radius: 50%;
	overflow: hidden;
	@include mq.mq($from: large) {
		width: 8rem;
		height: 8rem;
	}
}

.figure-cardBig {
	aspect-ratio: 7.7/6;
	margin-left: calc( var(--wrapper-gap) * -1 );
	margin-right: calc( var(--wrapper-gap) * -1 );

	@include mq.mq($from: medium) {
		margin-left: 0;
		margin-right: 0;
	}

	
}

.figure-square {
	aspect-ratio: 1/1;
}

.figure-square-fit {
	aspect-ratio: 1/1;
	--object-fit: contain;
}

.figure-cardRegular {
	aspect-ratio: 7.1/4;

}

.figure-cardCompact {
	aspect-ratio: 169/120;

	@include mq.mq($from: large) {
		aspect-ratio: 45/28;
	}
}

.figure-cardAside {
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	overflow: hidden;
	@include mq.mq($from: large) {
		width: 8rem;
		height: 8rem;
	}
}

.figure-cardSquare {
	aspect-ratio: 1/1;

	.card--horizontal & {
		width: 19.3rem;

		@include mq.mq($from: large) {
			width: 100%;
		}
	}
}

.figure-productRelated {
	aspect-ratio: var(--card-figure-aspect-ratio);
	--object-fit: contain;
	margin-bottom: auto;
	border: var(--img-border);

	img {
		--img-border: 0;
		top: 1rem;
		left: 1rem;
		width: calc(100% - 2rem);
		height: calc(100% - 2rem);

		@include mq.mq($from: large) {
			top: 2rem;
			left: 2rem;
			width: calc(100% - 4rem);
			height: calc(100% - 4rem);
		}
	}

}

.figure-productRegular {
	aspect-ratio: var(--card-figure-aspect-ratio);
	--object-fit: contain;
}

.figure-page404 {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 0;

	&:after {
		position: absolute;
		display: block;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		content: '';
		background-color: rgba(0, 0, 0, 0.3);
	}
}

video::-webkit-media-controls,
video::video::-webkit-media-controls-enclosure,
video::-webkit-media-controls-overlay-play-button {
	display: none !important;
	-webkit-appearance: none !important;

}


