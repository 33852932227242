@use "../mixins/mq";

.profile {
    position: relative;
    z-index: 4;
    &__button {
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        border: none;
        color: var(--color-grey-400);
        transition: color var(--animationDuration) var(--animationEase);
        display: block;



        &:hover,
        &:focus {
            color: var(--color-primary-1);
            --profile-abbreviation-bg-color: var(--color-primary-1);
        }

        &--close {
            margin-left: auto;
            padding: .4rem;
            color: var(--color-primary-1);

            &:after {
                    position: fixed;
                    background: transparent;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    content: "";
                    display: block;
                    z-index: -1;
            }
        }
    }

    &__abbreviation {
        text-transform: uppercase;
        border-radius: 50%;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 600;
        word-break: normal;
        color: var(--color-white);
        background-color: var(--profile-abbreviation-bg-color, var(--color-black));
        transition: background-color var(--animationDuration) var(--animationEase);
      
      
    }

    &__logout {
        font-size: inherit;
        display: inline-block;
        padding: 0;
        line-height: inherit;
    }

    &__dropdown {
        display: none;
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        right: -1rem;
        top: 4.1rem;
        background-color: var(--color-white);
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
        width: 25rem;
        max-width: 100vw;
        border: .1rem solid var(--color-grey-300);
        border-width: 0 .1rem;
        font-size: 1.6rem;

        &--show {
            display: block;
        }
    }

    &__option {
        margin: 0;
        padding: .8rem 2.2rem;
        border-bottom: .1rem solid var(--color-grey-300);
    }

}
