@use "../mixins/mq";

.nav--footer-nav {
	display: block;
	grid-area: footerNav;
	margin-top: 6rem;


	@include mq.mq($from: large) {
		padding-right: 14rem;
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-top: 0;
	}

	.nav__wrap {
		flex-direction: column;
		margin-bottom: 4rem;
	}

	.nav__item {
		margin-top: 2rem;
		padding-right: 1rem;

		&:first-child {
			margin-top: 0;
		}

		@include mq.mq($from: large) {
			margin-top: 1.2rem;

			&:first-child {
				margin-top: 0;
			}
		}
	}

	.nav__link {
		font-size: 1.8rem;
		color: inherit;

		.not-touch & {
			&:hover {
				color: var(--color-primary-1);
			}
		}

		&.is-active {
			color: var(--color-primary-1);
		}
	}
}
