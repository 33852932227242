@use "../mixins/mq";

.stickybar {
    --deal-info-font-size: 1.2rem;
    --stickybar-y: -5rem;
    position: sticky;
    bottom: 0;
    z-index: 10;
    left: 0;
    right: 0;
    background-color: var(--color-white);
    color: var(--body-color);
    box-shadow: -2px -2px 10px 0 rgba(0, 0, 0, 0.1);
    max-width: 100%;
    overflow: hidden;
    transition: transform var(--animationDuration) var(--animationEase);
    transform: translateY( var( --stickybar-y ) );

    &--with-infobanner {
        --stickybar-y: -7.2rem;
    }

    &--bottom {
        --stickybar-y: 0;
    }

    &--black {
        background-color: var(--color-grey-600);
        color: var(--color-white);
    }

    @include mq.mq($from: large) {
        --stickybar-y: 110%;
        --deal-price-flex-base: auto;
        &--reveal {
            --stickybar-y: 0%;
        }
    }

    &__container {
        padding: 0 var(--wrapper-gap);
        max-width: 160rem;
        gap: 1rem;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include mq.mq($from: large) {
            gap: 2rem;
        }
    }

    &__col {
        margin: 0;
        padding: 1rem 0;
        &--show-large {
            @include mq.mq($until: large) {
                display: none;
            }
        }
        &--aligne-left {
            margin-right: auto;
        }

        &--hr {
            border-left: .1rem solid #cccccc88;
            align-self: stretch;
        }
    }

    &__figure {
        height: 9rem;
        width: 9rem;
        aspect-ratio: 1/1;
        border-radius: 50%;;
        overflow: hidden;
        position: relative;
    }

   
}