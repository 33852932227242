@use "../mixins/mq";

.variant {
    --variant-info-color: var(--color-grey-550);
    --variant-info-price: var(--color-primary-1);

    position: relative;
    border: .1rem solid var(--color-grey-400);
    background-color: var(--color-grey-100);
    font-size: 1.4rem;
    line-height: 1.5;
    letter-spacing: .025rem;
    user-select: none;
    color: var(--color-black);

    @include mq.mq($from: medium) {
        font-size: 1.6rem;
    }

    &__select {
        display: grid;
        padding: 0.3rem 1.5rem;
        align-items: center;
        min-height: 5rem;
        //box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5);
        cursor: pointer;

        background-color: transparent;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='20'%3E%3Cpath transform='rotate(-180 20 10)' d='M0,17l2.6,3L20,4.6L37.4,20l2.6-3L21.3,0.5c-0.8-0.7-1.9-0.7-2.6,0L0,17z'/%3E%3C/svg%3E");
        background-position: center right 1.5rem;
        background-repeat: no-repeat;
        background-size: 1.5em;
        color: inherit;
        padding-right: 3em;
        appearance: none;
        list-style: none;
        -webkit-appearance: none;


        [open] & {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='20'%3E%3Cpath d='M0,17l2.6,3L20,4.6L37.4,20l2.6-3L21.3,0.5c-0.8-0.7-1.9-0.7-2.6,0L0,17z'/%3E%3C/svg%3E");
        }

    }

    &__slot {
        display: block;
        list-style: none;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 4;
        left: 0;
        right: 0;
        bottom: 100%;
        max-height: 30rem;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
        scroll-behavior: smooth;

        background-color: var(--color-white);

        /* open after */
        @include mq.mq($from: medium) {
            top: 100%;
            bottom: auto;
        }
    }

    &__option {
        position: relative;
        margin: 0;
    }

    &--disabled,
    [disabled] {
        opacity: .5;
        cursor: auto;
    }

    &__radio {
        position: absolute;
        left: 0;
        top: 0;
        //right: 0;
        width: 4rem;
        bottom: 0;
        margin: 0;
        padding: 0;
        border: 0;
        appearance: none;
        cursor: pointer;
    }

    &__sold-out {
        color: var(--color-primary-1);
        font-weight: 600;
    }

    &__label {
        display: flex;
        padding: 1rem;
        gap: 1.6rem;
        align-items: center;
        --paragraph-spacing: 0;
        border-bottom: .1rem solid var(--color-grey-300);


        :checked + & {
            --variant-radio-bg: radial-gradient(circle, var(--color-primary-1) 0%, var(--color-primary-1) 50%, transparent 51%);
            background-color: var(--color-grey-100);
        }

        &:hover,
        :focus + & {
            background-color: var(--color-grey-200);
        }

        [disabled] + & {
            --variant-info-color: currentColor;
            --variant-info-price: currentColor;
            --variant-progress-display: none;
            --variant-thumbnail-opacity: .4;
            --variant-radio-opacity: 0;

            color: var(--color-grey-400);
            background-color: transparent;
        }

        &:before {
            content: "";
            height: 1.9rem;
            aspect-ratio: 1/1;
            border-radius: 50%;
            opacity: var(--variant-radio-opacity,1);

            border: 1px solid var(--color-primary-1);

            background: var(--variant-radio-bg,transparent);
        }
    }

    &__price {
        color: var( --variant-info-price);
        font-weight: 600;
    }

    &__info {
        font-size: 1.2rem;
        color: var(--variant-info-color);
        letter-spacing: .075rem;
    }

    &__preview {
        position: relative;
    }

    &__flag {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        text-transform: uppercase;
        padding: 0.5rem;
    }

    &__thumbnail {
        display: block;
        width: var(--variant-thumbnail-width,9rem);
        min-width: var(--variant-thumbnail-width,9rem);
        height: 9rem;
        object-fit: contain;
        background-color: var(--color-white);
        padding: .5rem;
        border-radius: .2rem ;
        border: .1rem solid var(--color-black);
        transition: border-color var(--animationDuration) var(--animationEase);
        -webkit-user-drag: none;
        margin: 0;
        overflow: hidden;
        opacity: var(--variant-thumbnail-opacity, 1);
    }
       
    &__progress {
        display: var(--variant-progress-display, block);
        height: .5rem;
        background-color: var(--color-grey-550);
        background-image: linear-gradient(-90deg, transparent 0px, transparent 2px,  #54545480 2px,  #54545480 3px);
        background-size: 3px;
        background-position: left center;
        position: relative;
        margin: -.25rem 0 .7rem;
        border-radius: 0 0 .2rem .2rem;


        &--detached {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            margin: 0;
        }

        &:after {
            content: "";
            display: block;
            filter: drop-shadow(0 .2rem 1rem rgba(0,0,0,.5));
            background-color: var(--color-primary-1);
            left: 0;
            bottom: 0;
            max-width: 100%;
            width: var(--deal-progress,100%);
            height: 100%;
        }
    }


}