.embeddedpost {

    &--stretch {
        display: grid;
        justify-items: stretch;
        max-width: 55rem;
        margin: 0 auto;
    }

    iframe:not([src]),
    iframe[url=""] {
        display: none;
    }
    
    iframe,
    blockquote {
        background-color: var(--color-grey-100);
        margin: 0;
        padding: 0;
        &:before,
        &:after {
            display: none;
        }
       
    }

}