@use "../mixins/mq";

.partner {
    display: flex;
    gap: 1rem 1.2rem;
    flex-direction: column;
    --partner-logo-max-width: 8.1rem;

    &--inline {
        --partner-logo-max-width: 6.4rem;
        --partner-logo-max-height: 3rem;
       /* --partner-logo-max-width: 10rem;
        --partner-logo-max-height: 4rem;*/
        flex-direction: row;
        align-items: center;

    }

    &--short {
        text-transform: uppercase;
        margin-bottom: 2rem;
    }

    &__text {
        font-size: var(--partner-text-font-size,1.6rem);
        letter-spacing: var(--partner-text-letter-spacing,-0.03rem);
        display: var(--partner-text-display,block);
    }

    &__logo {
        display: block;
        //height: auto;
        max-width: var(--partner-logo-max-width,none);
        height: var(--partner-logo-max-height,none);
        object-fit: contain;
        //max-height: var(--partner-logo-max-height,none);
    }

    @include mq.mq($from: large) {
        &--inline {
            //--partner-logo-max-width: 15.8rem;
        }
    }
}

