@use "../mixins/mq";
@use "../_base/typography";


.nav--meta-nav {
	padding-left: 1.8rem;


	.nav__item {
		&:first-child {
			--nav-link-inline-border-color: transparent;
		}
	}

	.nav__wrap--parent {
		gap: 1rem 1.5rem;
		--nav-wrap-area: headerNavWrap;
	}

	.nav__item--parent {
		display: flex;
		align-items: center;
	}

	.nav__link--parent {
		--outline-color: var(--color-black);
		--button-primary-min-height: 2.5rem;
		--button-primary-min-width: 6rem;
		--nav-link-font-size: 1.2rem;
		--nav-link-font-weight: 400;
		--nav-link-padding-inline: 1rem;
		padding-bottom: 0.05em;
		margin: 0;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-height: var(--button-primary-min-height);
		color: var(--color-white);
		border-radius: var(--button-primary-min-height);
		background-color: var(--color-grey-600);
	}

	@include mq.mq($from: large) {
		--nav-wrap: nowrap;
		--nav-link-font-size: 1.4rem;

		

		padding-left: var(--wrapper-gap);
		margin: 0;

		.nav__link--parent {
			--outline-color: var(--color-black);
			--button-primary-min-height: 3.4rem;
			--nav-link-font-size: 1.8rem;
			--nav-link-font-weight: 400;
			--nav-link-padding-inline: 1.4rem;

			cursor: pointer;
			max-width: 100%;
			margin: 0;
			overflow: visible;
			position: relative;
			border: none;
			letter-spacing: .04rem;

			min-width: var(--button-primary-min-width);
			padding-block: 0;
			padding-inline: var(--nav-link-padding-inline);
			text-align: center;
			transition: background-color var(--animationDuration) var(--animationEase), color var(--animationDuration) var(--animationEase), border-color var(--animationDuration) var(--animationEase);

			&:hover,
			&:focus,
			&.is-active {
				background-color: var(--color-grey-500);
			}
		}
	}

	.nav__link--child {
		background: none;
	}
}