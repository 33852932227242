@use "../mixins/mq";

.c-area-main__header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include mq.mq($from: large) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.c-area-main__title {
  position: relative;
  margin-bottom: 2rem;
  width: 100%;

  &:after {
    position: absolute;
    left: 0;
    top: 100%;
    display: block;
    width: 100%;
    height: 1px;
    content: '';
    background: var(--dottedBackground) center left repeat;
    background-size: 5px 1px;
  }

  @include mq.mq($from: large) {
    width: auto;
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }
}
