@use "sass:color";
@use "../_config/colors";
@use "../_base/typography";
@use "../atoms/action";
@use "../mixins/mq";

.cookiebanner {
  position: fixed;
  right: 0;
  bottom: 0;

  z-index: 100;
  background-color: color.adjust(colors.$color-black, $alpha: -0.2);

  color: var(--color-white);
  padding: 2rem;

  width: auto;
  max-width: 100%;

  @include mq.mq($from: medium) {
    max-width: 36rem;
  }
}

.cookiebanner__title {
  @extend .font-size-h5;
  @extend .font-weight-700;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.cookiebanner__desc {
  @extend .font-size-h5;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.cookiebanner__cta {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.cookiebanner__button {
  @extend .action;
  @extend .action-primary;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  min-width: unset;
}

.cookiebanner__button--light {
  @extend .action;
  @extend .action-secondary--inverted;
  margin-right: 0;
}

.cookiebanner__closebutton {
  position: absolute;
  right: 0;
  top: 0;

  width: 40px;
  height: 40px;

  background: none;
  border: 0;

  transition: .2s all;

  &:focus {
    background: var(--color-black);
  }
}

.cookiebanner__closex {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  transition: .2s all;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 2rem;
    height: 1px;
    background: var(--color-white);

    position: absolute;
    top: 50%;
    left: calc(50% - calc(2rem / 2));
    transform-origin: center center;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  &:hover {
    transform: scale(1.2);
    cursor: pointer;
  }
}
