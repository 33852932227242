@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";

@use "../mixins/mq";


.nav {
	display: var(--nav-display, inline-flex);
	grid-gap: var(--nav--grid-gap, 0);
	grid-template-rows: var(--nav--grid-teamplate-rows, auto);
	grid-template-areas: var(--nav--grid-teamplate-areas, unset);
}

.nav__toggle.action {
	position: absolute;
	top: 0.5rem;
	right: -1.5rem;
	width: 6rem;
	height: 6rem;
	transform-origin: center center;
	transition: transform var(--animationDuration) var(--animationEase);
	color: inherit;

	.icon {
		color: var(--color-black);
	}


	.nav__item--parent-opened & {
		transform: rotate(180deg);
	}

	@include mq.mq($from: large) {
		display: none;
		visibility: hidden;
	}
}


.nav__wrap {
	display: flex;
	flex-wrap: var(--nav-wrap,wrap);
	margin: 0;
	padding: 0;
	list-style: none;
	//grid-area: headerNavWrap;


	&--child {
		display: none;
		padding-top: 1rem;
		padding-bottom: 2rem;
		background-color: var(--color-white);
		min-width: 19rem;
		box-shadow: 0 1.2rem 1rem 0 rgba(0, 0, 0, 1);

		@include mq.mq($from: large) {
			position: absolute;
			left: 0;
			top: 100%;
			display: none;
			flex-direction: column;
			padding: 1rem 2rem;
			opacity: 0;
			transform: translate3d(-1.6rem, 1rem, 1px);
			white-space: nowrap;
			box-shadow: 0 2px 20px 0 #1A191920;
		}
	}

	&.nav__wrap--visible {
		display: flex;
		z-index: 9998;
		visibility: visible;
	}
}

.nav__header {
	display: none;
	text-transform: uppercase;
	font-size: 1.4rem;
	letter-spacing: .1rem;
	line-height: 1.571428571;
	font-weight: normal;
	color: var(--color-grey-500);
	text-align: left;
	font-family: var(--titleFontFamily);

}

.nav__item {
	position: relative;
	padding-left: 0;
	margin-top: 0;
	
	.icon-chevron-down,
	.icon-chevron-down svg {
		@include mq.mq($from: large) {
			width: 20px;
			height: 12px;
		}
	}
}

.nav__item--child {
	width: 100%;
	border-top: 1px solid var(--color-grey-200);

	&:first-child {
		border-top: 0;
	}

	/*&:hover {
		@include background-svg.params(svg.$svg-arrow-right, transparent, right, center, no-repeat, 22px, 22px, colors.$color-primary-1);
	}*/
}

.nav__link {
	--nav-inline-border: 0;
	font-size: var(--nav-link-font-size, inherit);
	font-weight: var(--nav-link-font-weight, inherit);
	text-decoration: none;
	border-left: var(--nav-inline-border);
	color: var(--color-black);
	transition: color var(--animationDuration) var(--animationEase);


	&:hover,
	&.is-active {
		color: var(--color-primary-1);
	}

	&--inline {
		--nav-inline-border: 1px solid var(--nav-link-inline-border-color);
		padding: 0 1.5rem;
		margin: 1.3rem 0 ;
		letter-spacing: .75px;
		display: block;

		.nav__item:first-child & {
			--nav-inline-border: 0;
		}
	}
}



.nav__link--child {
	display: flex;
	font-size: 1.6rem;
	line-height: 3.8rem;
	font-weight: 400;
}
