$fontPath: '../../webfonts/';


@font-face {
	font-family: 'Good Office Pro Narrow';
	src: url('#{$fontPath}Good_Offc_Pro_Narrow_Medium/Good_Offc_Pro_Narrow_Medium.woff2') format('woff2'),
	url('#{$fontPath}Good_Offc_Pro_Narrow_Medium/Good_Offc_Pro_Narrow_Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}



@font-face {
	font-family: 'Good Office Pro Narrow';
	src: url('#{$fontPath}Good_Offc_Pro_Narrow_Ultra/Good_Offc_Pro_Narrow_Ultra.woff2') format('woff2'),
	url('#{$fontPath}Good_Offc_Pro_Narrow_Ultra/Good_Offc_Pro_Narrow_Ultra.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}


@font-face {
	font-family: 'Good Office Pro Cond';
	src: url('#{$fontPath}Good_Offc_Pro_Cond_Ultra/Good_Offc_Pro_Cond_Ultra.woff2') format('woff2'),
	url('#{$fontPath}Good_Offc_Pro_Cond_Ultra/Good_Offc_Pro_Cond_Ultra.woff') format('woff');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Good Office Pro';
	src: url('#{$fontPath}Good_Offc_Pro_Bold/GoodOffcPro-Bold.woff2') format('woff2'),
	url('#{$fontPath}Good_Offc_Pro_Bold/GoodOffcPro-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Good Office Pro';
	src: url('#{$fontPath}Good_Offc_Pro_Medium/GoodOffcPro-Medium.woff2') format('woff2'),
	url('#{$fontPath}Good_Offc_Pro_Medium/GoodOffcPro-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url('#{$fontPath}Source_sans_pro_regular/source-sans-pro-v21-latin-regular.woff2') format('woff2'),
	url('#{$fontPath}Source_sans_pro_regular/source-sans-pro-v21-latin-regular.woff') format('woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: url('#{$fontPath}Source_sans_pro_semi-bold/source-sans-pro-v21-latin-600.woff2') format('woff2'),
	url('#{$fontPath}Source_sans_pro_semi-bold/source-sans-pro-v21-latin-600.woff') format('woff');
}

@font-face {
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url('#{$fontPath}Source_sans_pro_bold/source-sans-pro-v21-latin-700.woff2') format('woff2'),
	url('#{$fontPath}Source_sans_pro_bold/source-sans-pro-v21-latin-700.woff') format('woff');
}


// new web fonts

//Itiger
@font-face {
	font-family: 'inter';
	font-display: swap;
	src: url('#{$fontPath}inter/InterVariable.woff2') format('woff2');
}

@font-face {
	font-family: 'Blick Variable';
	font-display: swap;
	src: url('#{$fontPath}blick-variable/Blick-ExtraBold.woff2') format('woff2');
}

@font-face {
	font-family: 'blick-roman';
	font-display: swap;
	src: url('#{$fontPath}blick-variable/BlickVariable-Roman.woff2') format('woff2');
}