table {
  border-collapse: collapse;
  border: 0;
  width: 100%;
  margin: 2rem 0;
  letter-spacing: .5px;
  font-weight: normal;

  strong {
    font-weight: 600;
  }

  tr {
    border-bottom: dashed 1px var(--color-grey-600);
  }

  thead {
    border-bottom: 2px solid var(--color-primary-1);

    th {
      font-weight: bold;
    }
  }
}
