.consentframe {
    display: grid;
    align-content: center;
    border: var(--color-grey-300) solid .1rem;
    background-color: var(--color-grey-100);
    padding: 3rem 2.5rem;
   
    --paragraph-spacing: 1rem;
    --link-color: var(--color-primary-1);
    --link-text-decoration: underline;
    --link-hover-text-decoration: none;

}