@use "../mixins/mq";

.box {
  margin: 0 2rem 6rem;
  user-select: none;

  &--horizontal {
    max-width: 32rem;

    @include mq.mq($from: 362px) {
      margin-left: auto;
      margin-right: auto;
    }

    @include mq.mq($from: large) {
      max-width: 99.6rem;
    }

    @include mq.mq($from: 1016px) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  /*&--newsFirst {
    max-width: 32.2rem;

    @include mq.mq($from: 362px) {
      margin-left: auto;
      margin-right: auto;
    }

    @include mq.mq($from: large) {
      max-width: 99.6rem;
    }
  }*/

  &--vertical {
    max-width: 35.5rem;

    @include mq.mq($from: 395px) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include mq.mq($from: large) {
    margin-bottom: 10rem;
  }

  * + & {
    margin-top: 6rem;

    @include mq.mq($from: large) {
      margin-top: 10rem;
    }
  }

  &__inner {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    text-align: center;
    line-height: 0;
    overflow: hidden;
    border: 1px solid var(--color-grey-300);

    &--vertical {
      min-height: 60.2rem;

      /*@include mq.mq($from: large) {
        min-height: 82rem;
      }*/
    }

    &:after {
      position: absolute;
      left: 2rem;
      top: 2rem;
      display: block;
      content: '';
      width: calc(100% - 4rem);
      height: calc(100% - 4rem);
      background-color: var(--color-grey-200);
    }

    &--horizontal {
      min-height: 64rem;

      @include mq.mq($from: large) {
        //min-height: 60.2rem;
        min-height: 25.2rem;
      }
    }

    /*&--newsFirst {
      min-height: 29rem;

      @include mq.mq($from: large) {
        min-height: 54rem;
      }
    }*/

    &--isSmall {
      min-height: 64rem;

     @include mq.mq($from: large) {
       min-height: 25.2rem;
     }
   }

    & > div {
      display: block !important;
    }

    &.adm-loaded,
    &--is-ready {
      &:after {
        display: none;
      }
    }
  }

  &--sticky {
    @include mq.mq($from: medium) {
      position: sticky;
      top: 12rem;
    }
  }

  .article__header-wrap + & {
    margin-top: 0;
  }
}
