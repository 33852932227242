@use "../mixins/mq";


.breadcrumb {
	--link-color: var(--color-grey-400);
	color: var(--color-grey-500);
	display: flex;
	list-style: none;
	margin-top: 0;
	margin-left: 0;
	font-size: 1.2rem;
	@include mq.mq($from: large) {
		font-size: 1.6rem;
	}
}

.breadcrumb__wrapper {
	max-width: 100vw;
	white-space: nowrap;
	overflow: auto;
	height: 40px;
	//overflow-y: hidden;

	margin: 1.3rem 0 0;
	-webkit-overflow-scrolling: touch;

	@include mq.mq($from: large) {
		margin: 1.2rem 0 1.3rem;

	}
}

.breadcrumb--child {
	position: fixed;
	left: -22px;
	top: 100%;
	display: none;
	flex-direction: column;
	width: auto;
	max-width: 100%;
	padding: 1rem 0;
	background-color: var(--color-grey-300);
	word-break: keep-all;
	white-space: nowrap;
}

.breadcrumb__item {
	display: flex;
	align-items: center;
	height: 30px;
	overflow: visible;
	padding-left: 0;
	letter-spacing: 0.075rem;
	margin-right: 1.5rem;
	margin-top: 0;
	&:first-child {
		margin-right: .7rem;
	}
	&:last-child {
		padding-right: 2rem;
	}
}

.breadcrumb__separator {
	margin-right: 1.5rem;
	font-size: 1.8rem;
	color: var(--color-grey-400);
}

.breadcrumb__trigger {
	height: 30px;
	margin-left: var(--spacingSmall);
	color: var(--color-grey-300);

	.breadcrumb--light & {
		color: var(--color-white);
	}

	.icon {
		transform-origin: center center;
	}
}

.breadcrumb__item-title {
	display: inline-flex;
	align-items: center;
	height: 30px;
	text-decoration: none;

}

a.breadcrumb__item-title {
	.not-touch & {
		&:hover {
			text-decoration: underline;
		}
	}
}

.breadcrumb__item--home {
	margin-left: 0;
}

.breadcrumb__link--home {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--color-primary-1);
}

.breadcrumb__item--last {
	padding-right: var(--spacingRegular);
	color: inherit;
	cursor: default;
}

.breadcrumb__item--opened {
	.breadcrumb--child {
		display: flex;
	}

	.breadcrumb__trigger {
		.icon {
			transform: rotate(180deg);
		}
	}
}
