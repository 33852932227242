@use "../mixins/mq";

.hotbox {
  
  &--offset-1-col {
    @include mq.mq($from: xlarge) {
        margin-left: calc(8.33% + 6rem);
    }
  }

  &__list {
    padding-bottom: 6rem;
  }
}
