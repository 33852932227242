$size: 48px;

.loader {
  display: block;
  margin: 0 auto;
  width : $size;
  height: $size;
  border: 3px dotted var(--color-black);
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  position: relative;
  animation: rotation 2s linear infinite;
  grid-column: 1/-1;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px dotted var(--color-primary-1);
    border-style: solid solid dotted;
    width:  calc(#{$size}/2);
    height:  calc(#{$size}/2);
    border-radius: 50%;
    animation: rotationBack 1s linear infinite;
    transform-origin: center center;
  }
}

@keyframes rotation {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
}
@keyframes rotationBack {
  0% { transform: rotate(0deg) }
  100% { transform: rotate(-360deg) }
}
