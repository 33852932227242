@use "../mixins/mq";
@use "vars";

html {
	font-size: 62.5%;
	text-size-adjust: none;
	scroll-padding-top: var(--scroll-padding);
	scroll-behavior: smooth;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	font-family: var(--bodyFontFamily, vars.$bodyFontFamily);
	font-size: var(--globalFontSize);
	line-height: var(--globalLineHeight);
	letter-spacing: var(--globalLetterSpacing);
	background-color: var(--color-white);
	color: var(--body-color);
	hyphens: manual;
	font-variant: none;

	//background: white url('../../images/arrow-right.svg') 0 0 repeat;
	//background-size: 20px 20px;

	word-break: break-word;
}


main {
	//min-height: 200vh;
}


