@use "../mixins/mq";
@use "../_base/typography";


.nav--main-nav {
	margin-right: 1.8rem;

	@include mq.mq($from: large) {
		--nav-wrap: nowrap;
		margin: 0;

		margin-right: var(--wrapper-gap);

		.nav__wrap--parent {
			--nav-wrap-area: headerNavWrap;
		}

		.nav__link {
			font-size: 1.8rem;
			font-weight: 600;
			height: 3rem;
			letter-spacing: .9px;
			margin: 0;
			padding: 0;
			border: none;
		}
		.nav__wrap {
			gap: 1rem 2.1rem;
		}
	}
	/*
	@include mq.mq($from: xlarge) {
		.nav__wrap {
			gap: 1rem 3rem;
		}
	}
	*/
}