@use "../mixins/mq";


.hide-on-small {
  @include mq.mq($until: medium) {
    display: none;
    visibility: hidden;  
  }
}
.hide-on-medium {
  @include mq.mq($from: medium, $until: large) {
    display: none;
    visibility: hidden;  
  }
}
.hide-on-large {
  @include mq.mq($from: large) {
    display: none;
    visibility: hidden;  
  }
}