@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";

.back-top-top {
    @include background-svg.to-custom-property(svg.$svg-arrow-top, colors.$color-white,--back-to-top-icon);

    display: block;
    text-decoration: none;
    position: fixed;
    z-index: 52;
    left: 100%;
    top: calc(50% - 2.5rem);
    height: 5rem;
    width: 5.6rem;
    border-radius: 50% 0 0 50%;
    background-color: rgb(52 52 52 / 60%);
    background-image: var(--back-to-top-icon);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 2.8rem;
    box-shadow: 0 .2rem .4rem 0 rgba(0,0,0,var(--back-top-top-top-shadow-opacity,0));
    will-change: background-color transform;
    transition: background-color var(--animationDuration) var(--animationEase), transform var(--animationDuration) var(--animationEase);
    transform: translateX( var(--back-top-top-top-translate-x,0) );
    overflow: hidden;

    .scroll-up.scroll-passt-threshold &,
    &--revealed {
        --back-top-top-top-translate-x: -100%;
        --back-top-top-top-shadow-opacity: .5;
    }

    @include mq.mq($from: medium) {

        .scroll-passt-threshold & {
            --back-top-top-top-translate-x: -100%;
            --back-top-top-top-shadow-opacity: .5;
        }
       
    }



    &:focus, 
    &:hover {
        background-color: rgb(52 52 52 / 80%);
    }
}