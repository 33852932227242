@use "../mixins/mq";

.aside {
  //background: rgba(255, 0, 0, 0.4);
  //min-height: 500px;

  @include mq.mq($from: medium) {
    grid-area: aside;
  }


  &__title {
    margin-bottom: 2rem;
    margin-top: -.8rem;
  }
}


.aside__most-read {
  margin-bottom: 6rem;

  @include mq.mq($from: large) {
    margin-bottom: 10rem;
  }

  .card--aside + & {
    margin-top: 6rem;

    @include mq.mq($from: large) {
      margin-top: 10rem;
    }
  }
}
