@use "../_base/typography";
@use "../mixins/mq";

.bubble {

    --bubble-bg-color: var(--color-primary-1);

    
    --bubble-size: 6rem;
    //base xSmall or iFrame
    --bubble-font-size: 1.1rem;
    --bubble-line-height: 1;
    --bubble-letter-spacing: normal;

    //text-medium (All Uppercase)
    --bubble-text-medium-font-size: 1.1rem;
    --bubble-text-medium-line-height: 1.25;
    --bubble-text-medium-letter-spacing: normal;

    //text-large (All Uppercase)
    --bubble-text-large-font-size: 1.8rem;
    --bubble-text-large-line-height: 1.111111111;
    --bubble-text-large-letter-spacing: .045rem;


    display: inline-flex;
    vertical-align: top;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--bubble-bg-color);
    width: var(--bubble-size);
    height: var(--bubble-size);
    border-radius: 50%;
    color: var(--color-white);

    box-shadow: var(--bubble-box-shadow);
    transform: rotate( var(--bubble-rotate,-10deg));
    padding: var(--bubble-padding, 0 .5rem);
    text-align: center;
    
    letter-spacing: var(--bubble-letter-spacing);
    font-size: var(--bubble-font-size);
    line-height: var(--bubble-line-height);
    font-weight: 600;
    overflow: hidden;
    shape-outside: circle(50%);


    &--black {
        --bubble-bg-color: var(--color-black);
    }

    &__text {
        //inherit
    }

    //medium text
    &__text--medium {
        font-weight: 700;
        text-transform: uppercase;
        font-family: var(--titleFontFamily);
        letter-spacing: var(--bubble-text-medium-letter-spacing);
        font-size: var(--bubble-text-medium-font-size);
        line-height: var(--bubble-text-medium-line-height);
    }
    //large text
    &__text--large {
       font-weight: 700;
       font-family: var(--titleFontFamily);
       letter-spacing: var(--bubble-text-large-letter-spacing);
       font-size: var(--bubble-text-large-font-size);
       line-height: var(--bubble-text-large-line-height);
       text-transform: uppercase;
    }



    //@include mq.mq($from: medium) {
    @container deals (min-width: 510px) {

        //default is iFrame
        --bubble-size: 7rem;

        --bubble-font-size: 1.4rem;
        --bubble-line-height: 1.071428571;
        --bubble-letter-spacing: normal;

        //text-medium
        --bubble-text-medium-font-size: 1.3rem;
        --bubble-text-medium-line-height: 1.1;
        --bubble-text-medium-letter-spacing: normal;

        //text-large
        --bubble-text-large-font-size: 2.2rem;
        --bubble-text-large-line-height: 1.136363636;
        --bubble-text-large-letter-spacing: .055rem;


        &--size-s {
            --bubble-padding: 0 1.12rem;
            --bubble-size: 10rem;

            --bubble-font-size: 1.8rem;
            --bubble-line-height: 1.111111111;
            --bubble-letter-spacing: 0.045rem;
    
            //text-medium
            --bubble-text-medium-font-size: 1.4rem;
            --bubble-text-medium-line-height: 1.285714286;
            --bubble-text-medium-letter-spacing: normal;
    
            //text-large
            --bubble-text-large-font-size: 2.6rem;
            --bubble-text-large-line-height: 1.153846154;
            --bubble-text-large-letter-spacing: normal;
        }

        &--size-m {
            --bubble-padding: 0 2.3rem;
            --bubble-size: 14rem;

            --bubble-font-size: 1.8rem;
            --bubble-line-height: 1.388888889;
            --bubble-letter-spacing: 0.045rem;
    
            //text-medium
            --bubble-text-medium-font-size: 2.6rem;
            --bubble-text-medium-line-height: 1.153846154;
            --bubble-text-medium-letter-spacing: normal;
    
            //text-large
            --bubble-text-large-font-size: 3.5rem;
            --bubble-text-large-line-height: 1;
            --bubble-text-large-letter-spacing: 0.088rem;
        }
    }

    @include mq.mq($from: medium) {
        .deal--hero & {
            --bubble-text-large-font-size: 2.6rem;
            --bubble-size: 10rem;
            --bubble-font-size: 1.8rem;
        }
    }
}