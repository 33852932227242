@use "../mixins/mq";

.iframe-wrapper {
	padding: 0;
	container-type: var(--iframe-container-type, inline-size);

}

.iframe-wrapper {
	container-type: inline-size;
}

.iframe-container {
	position: relative;
	width: 100%;
	aspect-ratio: 16/9;

	&--newsletter {
		aspect-ratio: unset;
		height: 87rem;
		//max-width: 77rem;
		margin: auto;

		@container (min-width: 576px) {
			height: 89rem;
		}

		@container (min-width: 768px) {
			height: 62rem;
		}

		@container (min-width: 992px) {
			height: 57rem;
		}

		@container (min-width: 1400px) {
			height: 46rem;
		}

		/*@include mq.mq($from: 534px) {
			height: 36.5rem;
		}

		@include mq.mq($from: 584px) {
			height: 34rem;
		}

		@include mq.mq($from: 710px) {
			height: 32rem;
		}

		@include mq.mq($from: 802px) {
			height: 30rem;
		}

		@include mq.mq($from: 1250px) {
			height: 30rem;
		}*/
	}

	&--signup-box-deals {
		aspect-ratio: unset;
		min-height: 91rem;
		max-width: 120.2rem;
		margin-inline: auto;

		@container (min-width: 770px) {
			min-height: 64rem;
		}
		@container (min-width: 994px) {
			min-height: 60rem;
		}
		@container (min-width: 1202px) {
			min-height: 57rem;
		}
	}


	&--compact {
		height: 39rem;
		width: 28rem;
		background: linear-gradient(0deg, #5a0808 -7.01%, #2E363D 76.83%) no-repeat;
		background-size: 100% 7rem;
		border-radius: .6rem;

	}

	div, iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	iframe,
	div {
		position: absolute !important;
		width: 100% !important;
		height: 100% !important;
	
	}



}

.iframe__poster {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 1;
	transition: all .3s cubic-bezier(.25,.46,.45,.75);

	&--playing {
		opacity: 0;
		z-index: 0;
	}

	img {
		display: block;
		width: 100%	;
		height: 100%;
		object-fit: cover;
	}
}

.iframe__play {
	position: absolute;
	left: 50%;
	top: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	padding: 0;
	transform: translate3d(-50%, -50%, 0);
	z-index: 2;
	cursor: pointer;
	will-change: background-color;
	border-radius: 50%;
	transition: background-color 0.25s ease;
	background-color: var(--color-primary-1);
	color: var(--white);

	@include mq.mq($from: large) {
		width: 120px;
		height: 120px;
	}

	.icon {
		transform: translateX(2px);
		color: var(--color-white);

		@include mq.mq($from: large) {
			transform: translateX(5px);
		}
	}

	&:hover {
		background-color: var(--color-secondary-1);
	}
}
