@use "../mixins/mq";
@use "../utils/visually-hidden";

.header {
  position: static;
  z-index: 50;
  top: 0;
  width: 100%;

  transition: transform var(--animationDuration) var(--animationEase);

  @include mq.mq($from: large) {
    position: unset;

  }

  &--sticky {
    position: sticky;

    @include mq.mq($from: large) {
     &-big  {
      
      --header-scroll-y: 27rem;
       top: -50rem;
       //--header-scroll-y: calc( -100%  + 66rem);

       --header-wrapper-border-bottom: 1px solid var(--color-grey-300);
        &.header--scroll {
          //--header-main-transform: translateY( calc( -100% + 175px) )
          --header-wrapper-border-bottom: none;

          .container--narrow {
            //--container-narrow: auto;
          }
        }
         
       
        &:not(.header--scroll) {
          --header-wrapper-box-shadow: none;
        }


        .header__top {
          border-color: transparent;
        }

        .header__home-link {
          --icon-height: 12.5rem;
          margin-block: 2rem 0;
        }

      }
    }
  }


  &__placeholder {
    width: 2.1rem;
    aspect-ratio: 1;
  }

}

.header__profile {
  margin-top: .8rem;
}

.header__bottom {
  @include mq.mq($from: large) {
    padding: 2.6rem 0 2rem;
  }
}

.header__top {
  display: flex;
  justify-content: space-between;
  padding-inline: var(--wrapper-gap);
  padding-block: 1rem;
  border-bottom: 2px solid var(--color-primary-1);
  background-color: var(--color-white);
  position: relative;
  z-index: 1;
 
}
.header__main {
  background-color: var(--color-white);
  transform: var(--header-main-transform, none)
}



.header__brand-link {
  display: none;
  --icon-width: 29.7rem;
  margin: 3.2rem auto 1.8rem;

  .header__wrapper--big & {
    @include mq.mq($from: large) {
      display: inline-flex;
    }

  }
}

.header__link {
  display: inline-block;
  line-height: 0;
  margin: 2rem auto;

  @include mq.mq($from: large) {
    margin-top: 0;
    margin-bottom: 3.5rem;
  }

}

.header--scroll {
  transform: translateY(var(--header-scroll-y,-8.8rem));
}

.header__blick-link {
  display: inline-flex;
  --icon-width: 11.5rem;

  @include mq.mq($from: large) {
    --icon-width: 13.7rem;
  }
}

.header__home-link {
  display: flex;
  margin-inline: auto;
  --icon-height: 6rem;

 
}

.header__home-link-text {
  @extend .visually-hidden;
}


.header__scrollarea {

  overflow-x: auto;
  overflow-y: clip;
  //scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  font-size: 1.2rem;
  padding-block: 0;

  mask-image: linear-gradient(90deg,transparent,#000 1.5rem,#000 calc(100% - 4rem),transparent calc(100% - 1rem));

  &::-webkit-scrollbar {
    display: none
  }

  @include mq.mq($from: large) {
    
    //overflow: visible;
    //mask-image: none;
  }

}


.header__nav {
  --nav-wrap: no-wrap;
  white-space: nowrap;
  display: inline-flex;

  font-weight: 600; 
  flex-wrap: nowrap;
  flex-direction: row-reverse;

  @include mq.mq($from: large) {
    --nav-display: block;

    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    gap: 2rem;
    min-height: var(--header-nav-min-height, auto);

    .header--nav-big & {
      display: grid;
      gap: 0 3.5rem;
      grid-template-columns: 4.4fr 2.6fr;
      padding-left: calc(50% - 1048px / 2);
      text-align: center;
      margin: 0 auto;
      padding-right: 2rem;

          
      .nav__header {
        display: block;
        margin-bottom: 1rem;
      }

      .nav--meta-nav {
        margin-right: var(--header-pull-out, 0);


        .nav__wrap {
          gap: 0.5rem 3rem;
        }

        .nav__link {
          --button-primary-min-height: 5rem;
          --nav-link-font-size: 2.6rem;
          --nav-link-padding-inline: 2.2rem;
        }
      }

      .nav--main-nav {
        .nav__link {
          font-size: 2rem;
          line-height: 1.3;
        }
      }

    }
  }
}

.header--nav-big {
  --nav-display: grid;
  --nav--grid-gap: 0.88rem;

  @include mq.mq($from: large) {
    --nav--grid-teamplate-rows: 2.2rem 1fr;
    --nav--grid-teamplate-areas:
            "headerTitle"
            "headerNavWrap";
  }



}



.header__bottom {
  border-bottom: var(--header-wrapper-border-bottom,none);

  @include mq.mq($from: large) {


    .header__wrapper--big & {
      margin-top: auto;
      padding-top: 2rem;
      padding-bottom: 1.6rem;
      margin-bottom: 0.8rem;
    }
   

  }
}



.header__title {
  margin-top: 2.1rem;
  text-align: center;
  font-family: var(--titleFontFamily);
  color: var(--color-primary-1);

  @include mq.mq($from: large) {
    margin: 9rem 0 8rem;
  }
}

.header__wrapper {
  --wrapper-gap: 0;

  position: static;
  text-align: var(--header-wrapper-text-align, inherit);
  box-shadow: var(--header-wrapper-box-shadow,0 2px 10px 0 rgba(0, 0, 0, 0.1));


  @include mq.mq($from: large) {

    &--big {
      --header-wrapper-text-align: center;
    }

    &--big {
      min-height: 14.7rem;
      display: flex;
    }

    --wrapper-gap: 3.8rem;
  }

  

}


@include mq.mq($from: large) {
  .header--nav-big:not(.header--scroll) {
    .nav__wrap, 
    .nav--meta-nav{
      --nav-wrap: wrap;
      padding-inline: 0;
    }

    .header__scrollarea {
      overflow: unset;
      -webkit-mask-image: none;
    }
  }
}