@use "../mixins/mq";

.deal {

    --deal-gradient-bg-gradient-rotation: 0deg;
    --deal-gradient-bg-gradient: linear-gradient(var(--deal-gradient-bg-gradient-rotation), var(--color-black) 0%, transparent 100%);

    --deal-box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3);
    --deal-bg-image: var(--deal-cover);
    --deal-row-min-height: 44rem;
    --deal-container-padding-block: 2rem;
    --deal-container-padding-inline: 1rem;
    --deal-article-max-width: 80%;
    --slider-image-max-height: 30vh;
    --deal-pricesblock-margin: 1.5rem 0 0;
    --deal-row-align-items: end;
    --dealstripe-x-position: 2rem;
    --font-size-h5: 1.4rem;

    --font-size-h3: 2.6rem;
    --deal-info-font-size: 1.4rem;

    max-width: var(--deal-max-width, 192rem);
    margin: var(--deal-margin, 0 auto);
    position: relative;
    opacity: var(--deal-opacity, 1);


    &--teaser {
        display: grid;
        grid-template-rows: auto 1fr;
        height: 100%;
        max-width: 104.6rem;
        --deal-overlay-flex-direction: column;
    }

    &--product {
        --deal-container-padding-block: 2rem;
        --deal-container-padding-inline: var(--wrapper-gap);
        --deal-article-margin: 0 auto 1.5rem;
        --deal-overlay-top: -2rem;
        --deal-overlay-left: auto;
        --deal-overlay-right: 3rem;
        //--paragraph-spacing: 1.5rem;
        --deal-pricesblock-margin: 1.5rem 0;
        //--button-primary-min-width: none;
    }

    &--has-overlay {
        padding-top: calc(var(--deal-overlay-top) * -1);
    }

    .disabled-deal &,
    &--disabled {

        --deal-opacity: .5;
    }


    &__pulled-up {
        position: var(--deal-pulled-up-position, static);
        right: 0;
        bottom: 100%;
    }

    &__billboard {
        position: relative;
        display: grid;
        grid-template-rows: 1fr auto;
        height: 100%;
        z-index: 2;
        box-shadow: var(--deal-box-shadow);
        background-size: cover;
        background-color: var(--deal-bg-color);
        background-image: var(--deal-bg-image);
        background-position: var(--deal-cover-background-position, center center);
        padding: var(--deal-padding);
        color: var(--deal-color, inherit);


        &:before {
            content: "";
            display: var(--deal-gradient-display, none);
            background-image: var(--deal-gradient-bg-gradient);
            background-position: var(--deal-gradient-bg-position, center center);
            opacity: var(--deal-billboard-gradient-opacity, .7);
            mix-blend-mode: var(--deal-billboard-gradient-mix-blend-mode, multiply);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: var(--deal-billboard-gradient-z-index);
        }

        &:after {
            content: "";
            display: var(--deal-outline-display, none);
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            position: absolute;
            pointer-events: none;
            border: .8rem solid var(--color-primary-1);
        }
    }

    &__dialog {
        position: relative;
        margin: 0 auto;
        max-width: var(--deal-container-max-width, none);
    }


    /*
        &--partner-pulled-out {
            --deal-partner-top: -5rem;
        }
    */
    &__partner {
        --partner-text-font-size: var(--font-size-h6);
        --partner-text-letter-spacing: .075rem;

        display: flex;
        align-items: center;
        text-transform: uppercase;
        justify-content: flex-end;
        gap: 1.2rem;
        //margin: 0 auto 1rem 7rem;
        margin: 0 auto 1rem 0;
        width: 100%;
        font-size: 1.6rem;
        letter-spacing: 1px;
        white-space: nowrap;

        right: 0;
        top: var(--deal-partner-top);
        position: var(--deal-partner-position, relative);
        color: var(--deal-partner-color, inherit);

    }

    &__share {

        gap: 0 2rem;
        display: inline-flex;

        &--mobile {
            margin-top: 2rem;
            @include mq.mq($from: large) {
                display: none;
            }
        }

        &--desktop {
            margin-left: auto;
            @include mq.mq($until: large) {
                display: none;
            }
        }
    }

    &--hero {
        --deal-container-max-width: 160rem;
        --deal-box-shadow: none;

        @include mq.mq($from: medium) {
            --deal-media-max-height: none;
        }
    }


    .action-animation {
        animation: actionAnimation .25s ease-in-out;
    }

    /* styles */
    &--dark {
        --deal-color: var(--color-white);
        --variant-color: var(--color-white);
        --deal-bg-color: var(--color-black);
        --deal-bg-image: var(--deal-cover, linear-gradient(180deg, #252525 20%, #E20000 133%));
        --deal-header-text-shadow: 0 .2rem .6rem rgba(0, 0, 0, 0.8);
    }

    /* black */
    &--black {
        --deal-color: var(--color-white);
        --variant-color: var(--color-white);
        --deal-bg-color: var(--color-black);
        --deal-bg-image: var(--deal-cover, linear-gradient(190deg, #252525 20%, #5077BB 200%));

    }

    &--beige {
        --deal-bg-color: var(--color-secondary-2);
    }

    &--gradient {
        --deal-color: var(--color-white);
        --deal-gradient-display: block;
    }

    &--outline {
        --deal-outline-display: block;
    }

    &--overlay-outside {
        --deal-overlay-top: -3rem;
        --deal-overlay-left: 1.6rem;

    }

    /* styles end */


    &__pricesblock {
        display: flex;
        gap: var(--deal-pricesblock-gap, 0 1.5rem);
        align-items: flex-end;
        flex-direction: row;
        flex-wrap: wrap;
        margin: var(--deal-pricesblock-margin);
    }

    &__link {
      
        text-decoration: none;
        display: block;
        color: inherit;
        --outline-color: var(--body-color);
       

        &--overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            text-indent: -9999em;
            color: transparent;
            z-index: 3;
        }
    }

    &__wrap {
        position: relative;
        text-decoration: none;
        display: block;
        color: inherit;
        --outline-color: var(--body-color);
    }

    &__container {
        margin: 0 auto;
        width: 100%;
        max-width: var(--deal-container-max-width, none);
        padding: 0 var(--deal-container-padding-inline);

        &--block {
            padding-block: var(--deal-container-padding-block);
            padding-inline: var(--deal-container-padding-inline);
        }

        &--grid {
            display: grid;
            gap: var(--deal-container-grid-gap, 1.5rem);
            grid-template-columns: var(--deal-container-grid-template-columns, 1fr);
            align-items: center;
        }
    }


    &__row {
        position: relative;
        display: grid;
        grid-template-columns: var(--deal-row-grid-template-columns, 1fr);
        min-height: var(--deal-row-min-height, 30rem);
        justify-content: center;
        gap: var(--deal-row-gap, 2rem);
        align-items: var(--deal-row-align-items);
        height: 100%;
    }

    &__col {
        min-width: 0;

        &--article {

        }

        &--description {
            order: var(--deal-col-description-order);
        }


    }

    &__article {
        position: relative;
        max-width: var(--deal-article-max-width);
        margin: var(--deal-article-margin, 0 auto);
    }

    &__sticker {
        max-width: var(--deal--sticker-size, 6rem);

        &s {
            display: var(--deal-stickers-dispaly, block);
        }

    }

    &__action {
        --paragraph-spacing: 1.4rem;

        position: relative;
        z-index: 4;
    }

    &__overlay {
        gap: 1rem;
        position: var(--deal-overlay-position, absolute);
        z-index: 2;
        display: inline-flex;
        flex-direction: var(--deal-overlay-flex-direction);

        align-items: center;
        top: var(--deal-overlay-top);
        left: var(--deal-overlay-left);
        right: var(--deal-overlay-right);
    }


    &__media {
        //aspect-ratio: var(--deal-media-aspect-ratio,100/91);
        object-fit: contain;
        min-height: 14rem;
        max-height: var(--deal-media-max-height, 18rem);
        width: 100%;
        height: auto;
        margin: auto;
        display: block;
    }


    &__product {
        position: relative;
        width: 100%;
        margin: 0 auto;
    }

    &__flag {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        display: grid;
        align-content: center;
        justify-items: center;

        &-svg {
            max-width: 100%;
            height: auto;
            max-height: 100%;
            width: auto;
        }
    }

    &__description {
        --paragraph-spacing: .5rem;
        max-width: var(--deal-description-max-width);
        width: 100%;
        padding: var(--deal-description-padding, 0 1rem);
        margin: var(--deal-description-margin, 0);


    }

    &__reserve-space-for-bubble {
        display: var(--deal-reserve-space-for-bubble-display, none);
        float: right;
        width: 15rem;
        height: 11rem;
        shape-outside: ellipse();
    }

    &__header {
        margin: .3rem 0 0;
    }

    &__name {
        text-shadow: var(--deal-header-text-shadow, none);
    }

    &__info {
        font-size: var(--deal-info-font-size, inherit);
        text-shadow: var(--deal-info-text-shadow, none);
    }

    &__price {
        display: block;
        color: var(--color-primary-1);
        line-height: 1;
        white-space: nowrap;
        flex-basis: var(--deal-price-flex-base, 100%);
        text-shadow: var(--deal-price-text-shadow, none);
    }

    &__dealstripe {
        margin: 0;
        display: block;
        position: relative;
        z-index: 1;


        &--link {
            text-decoration: none;

            &:hover,
            &:focus {
                .dealstripe {
                    --dealstripe-bg-color: var(--color-grey-600);
                }
            }
        }
    }

    &__availabilityarea {
        &--sticky {
            position: var(--deal-evaluabilityarea-position, sticky);
            bottom: 0;
            z-index: 6;

            @include mq.mq($from: medium) {
                z-index: 2;
            }
        }
    }


    &__variants {
        position: relative;
        margin: 2rem 0;
        display: grid;
        gap: 0.5rem;
        z-index: 5;

        @include mq.mq($from: large) {
            margin: 4rem 0 4.5rem;
            max-width: 45rem;

        }
    }

    &__infobanner {
        --line-clamp-count: 1;
        background-color: #2B2B2B;
        z-index: 1;
        position: var(--deal-infobanner-position, relative);
        color: var(--color-white);
        text-align: center;
        font-size: var(--font-size-h6);
        line-height: var(--line-height-h6);
    }

    /* @include mq.mq($from: medium) { */
    @container deals (min-width: 500px) {
        --font-size-h3: 2.8rem;
        --deal-info-font-size: 1.6rem;
        --deal-reserve-space-for-bubble-display: block;
        --deal-gradient-bg-gradient-rotation: -90deg;
        --deal-row-gap: 8.5%;
        --deal-row-grid-template-columns: 4fr 3fr;
        --deal-media-max-height: 30rem;
        --deal-article-max-width: 46rem;
        --deal-container-padding-block: 2rem;
        --deal-container-padding-inline: 5.4rem;

        .deals__item--half & {
            --font-size-h3: 2.6rem;
            --deal-container-padding-block: 2rem;
            --deal-container-padding-inline: 1.5rem;
            --deal-row-grid-template-columns: 2fr 3fr;
            --deal-row-min-height: 18.4rem;
        }


        /* type product */
        &--product {
            --deal-container-padding-block: 3rem;
            --deal-container-padding-inline: 3rem;
            --deal-description-max-width: 80rem;
            --deal-article-margin: 0 auto 0 0;
            --deal-pricesblock-margin: 1.5rem 0;

            --deal-container-grid-gap: 8.5%;
            --deal-container-grid-template-columns: 1fr 1fr;
        }

        &--description-left {
            --deal-row-grid-template-columns: 3fr 4fr;
            --deal-col-description-order: -1;
            --deal-overlay-flex-direction: auto;
            --deal-overlay-left: auto;
            --deal-overlay-right: 24rem;
            --deal-overlay-top: -2.5rem;
            --deal-gradient-bg-gradient-rotation: 90deg;

            .deals__item--half & {
                --deal-row-grid-template-columns: 3fr 2fr;
            }
        }

        &__row {
            align-items: center;
        }

        &__description {
            --deal-description-padding: 0;
            --paragraph-spacing: 1rem;
        }
    }


    @include mq.mq($from: medium) {
        &--hero {
            --font-size-h5: 1.8rem;
            --font-size-h3: 3.5rem;
            --deal-info-font-size: 2rem;
            --deal-row-gap: 6rem;
            --deal-article-max-width: 55rem;
            --deal-row-align-items: center;
            --slider-image-max-height: 36rem;
            --deal-row-grid-template-columns: 1fr 1fr;
            --deal-price-flex-base: auto;
            --deal-overlay-flex-direction: column;
            --deal-container-padding-block: 4rem;
            --deal-container-padding-inline: 5.4rem;
            --deal-pricesblock-margin: 3rem 0;
        }
    }

    @container deals (min-width: 510px) {
        --deal-pricesblock-margin: 3rem 0 0;
        --deal-row-min-height: 34.4rem;
        --dealstripe-x-position: 15%;

        &__action {
            --paragraph-spacing: 2.5rem;
        }

        &--overlay-outside {
            --deal-overlay-top: -4.5rem;
            --deal-overlay-left: 3.3rem;
        }
    }


    &__show-for-large {
        @include mq.mq($until: large) {
            display: none;
        }
    }

    /*@include mq.mq($from: large) {*/
    @container deals (min-width: $mq-breakpoints.large) {
        --deal--sticker-size: 8rem;

    }

    @include mq.mq($from: large) {
    //no sticky for large
        &--hero {
            --deal-evaluabilityarea-position: static;
            --deal-pulled-up-position: absolute;
            --deal-pricesblock-margin: 3rem 0;
        }
    }

    &__countdown {
        text-align: center;
        color: var(--color-white);
        background-color: var(--color-black);
        font-size: 1.4rem;
        line-height: 2.5rem
    }

    &-preview {
        --deal-margin: 5rem 0 0;
        --promoframe-margin: 0;
    }

    &-preview--iframe {
        position: relative;
        max-width: 95.4rem;
        height: 130rem;
        margin-top: 5rem;

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}



@keyframes actionAnimation {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.02);
        opacity: .8;
    }

    100% {
        transform: scale(1)
    }
}